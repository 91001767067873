<template>
  <div>
    <ProductPlatformUploadButtonList
      v-bind:selected-products="this.selectedProducts"
      @upload="this.uploadProductsToPlatform"
      @remove="this.removeProductsFromPlatform"
    />
    <ProductListMenuFilters
      v-bind:desired-filters="this.desiredFilters"
      v-bind:current-page="this.currentPage"
      @updateKeywordSearch="this.updateKeywordSearch"
      @updateFilterSearch="this.updateFilterSearch"
    />
  </div>
</template>

<script>
import ProductListMenuFilters from "@/views/store/products/list/menu/filters/ProductListMenuFilters.vue";
import ProductPlatformUploadButtonList from "@/views/store/products/list/menu/platform/ProductPlatformUploadButtonList.vue";

export default {
  components: {
    ProductListMenuFilters,
    ProductPlatformUploadButtonList,
  },
  computed: {
    desiredFilters: function () {
      return ["productTypes", "vendors", "tags", "offerStatuses", "approvalStatuses"];
    },
  },
  props: ["selectedProducts", "currentPage"],
  methods: {
    uploadProductsToPlatform: function () {
      this.$emit("upload", this.selectedProducts);
    },
    removeProductsFromPlatform: function () {
      this.$emit("remove", this.selectedProducts);
    },
    updateKeywordSearch: function (keywords) {
      this.$emit("updateKeywordSearch", keywords);
    },
    updateFilterSearch: function (filterName, filterOptions) {
      this.$emit("updateFilterSearch", filterName, filterOptions);
    },
  },
};
</script>
