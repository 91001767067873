<template>
  <div>
    <h1 class="text-center font-extrabold text-xl mb-5">{{ $t("Add a user") }}</h1>
    <VeeForm v-slot="{ handleSubmit }" v-bind:validation-schema="schema" as="div" class="w-full" ref="form">
      <form v-on:submit.prevent="handleSubmit($event, submit)">
        <div class="grid grid-cols-2 gap-4">
          <Selector id="role" :label="$t('Role')" name="role" v-model="formData.role" :options="roles" />
          <MultiSelect
            v-if="!roleAdminSelected"
            id="stores"
            required
            :label="$t('Selling points')"
            :placeholder="$t('Select selling points')"
            v-model="formData.storeIds"
            :options="storesOptions"
          />
          <div v-else></div>
          <div>
            <FormField
              id="firstName"
              :label="$t('First Name')"
              name="firstName"
              required
              v-model="formData.firstName"
            />
            <ErrorMessage name="firstName" class="mt-2 text-sm text-red-600" />
          </div>
          <FormField id="last_name" :label="$t('Last Name')" name="last_name" required v-model="formData.lastName" />
          <div>
            <FormField id="email" :label="$t('Email Address')" name="email" required v-model="formData.email" />
            <ErrorMessage name="email" class="mt-2 text-sm text-red-600" />
          </div>
        </div>

        <div class="mt-4 flex justify-end">
          <Loading v-if="isLoading" />
          <GenericButton v-else>
            {{ $t("Validate") }}
          </GenericButton>
        </div>
      </form>
    </VeeForm>
    <div v-if="error" class="my-3">
      <span class="text-red-500 text-sm">{{ error }}</span>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { Form as VeeForm, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import UserService from "@/api/services/user";
import FormField from "@/components/utils/FormField.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import Loading from "@/components/utils/Loading.vue";
import Selector from "@/components/utils/Selector.vue";
import MultiSelect from "@/components/utils/MultiSelect.vue";

export default {
  components: {
    FormField,
    GenericButton,
    Loading,
    Selector,
    MultiSelect,
    VeeForm,
    ErrorMessage,
  },

  data() {
    const schema = yup.object({
      firstName: yup.string().min(2, this.$t("First Name must be at least 2 characters")),
      email: yup.string().required(this.$t("Email is required")),
    });

    return {
      isLoading: false,
      formData: {
        firstName: "",
        lastName: "",
        profileType: "StoreManager",
        email: "",
        storeIds: [],
        role: "",
      },
      roles: [],
      storesOptions: [],
      error: "",
      schema,
    };
  },

  created() {
    this.setupFormData();
  },

  computed: {
    roleAdminSelected() {
      return this.formData.role === "organization_manager";
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async setupFormData() {
      try {
        this.isLoading = true;
        const {
          data: { roles, stores },
        } = await UserService.newUser();
        this.roles = roles;
        this.storesOptions = stores.map((store) => ({
          value: store.id,
          label: store.name,
        }));
        this.formData.role = this.roles[0].value;
      } catch (error) {
        console.error(error);
        this.error = error.response?.data?.error || [error.message];
      } finally {
        this.isLoading = false;
      }
    },

    async submit() {
      this.isLoading = true;
      if (this.roleAdminSelected) this.formData.profileType = "OrganizationManager";
      try {
        await UserService.createUser(this.formData);
        this.notify({
          category: "simple",
          type: "success",
          title: "Utilisateur créé",
          text: "Un email d'invitation a été envoyé à l'utilisateur",
        });
        this.$emit("success");
      } catch (error) {
        console.error(error);
        this.error = error.response?.data?.error || [error.message];
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
