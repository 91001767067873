<template>
  <Modal :open="openModal" @close="closeIncentiveModal">
    <h1 class="text-center font-black text-xl">
      {{ $t("Configure sellers incentive") }}
    </h1>

    <p class="mt-10 text-sm">
      {{ $t("You can configure the incentive model for your sellers here.") }}
    </p>
    <p class="mt-4 text-sm">
      {{
        $t(
          "Once the incentive is configured, you will be able to track the payments to be made to your sellers from the 'Compensation Tracking' page."
        )
      }}
    </p>
    <p class="mt-4 text-sm">
      {{
        $t(
          "The configurable incentive model is a proportional incentive that corresponds to a percentage of the annualized merchant's remuneration."
        )
      }}
    </p>

    <div class="my-10">
      <label for="seller-incentive-percentage" class="block text-sm font-medium text-gray-700">
        {{ $t("Percentage of the compensation to be paid") }}
      </label>
      <FormField
        name="localSellerIncentivePercentage"
        v-model="localSellerIncentivePercentage"
        id="seller-incentive-percentage"
        trailing-add-ons="%"
      />
    </div>

    <div class="flex justify-center">
      <button
        type="button"
        class="inline-flex items-center rounded-2xl border border-transparent text-button-font-primary bg-primary uppercase px-6 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
        @click="updateIncentive"
      >
        {{ $t("Confirm") }}
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import FormField from "@/components/utils/FormField.vue";

export default {
  components: {
    Modal,
    FormField,
  },

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    sellerIncentivePercentage: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      localSellerIncentivePercentage: this.sellerIncentivePercentage * 100,
    };
  },

  methods: {
    closeIncentiveModal() {
      this.$emit("closeIncentiveModal");
    },

    updateIncentive() {
      this.$emit("updateIncentive", this.localSellerIncentivePercentage / 100);
      this.closeIncentiveModal();
    },
  },

  watch: {
    sellerIncentivePercentage(newVal) {
      this.localSellerIncentivePercentage = newVal * 100;
    },
  },
};
</script>
