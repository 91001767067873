<template>
  <div class="bg-primary-light p-2 cursor-pointer text-primary-dark" @click="this.openModal = true">
    <div>
      <div class="flex flex-rows justify-center items-center">
        <p class="font-bold text-sm justify-self-center">🚀 Nouveau challenge en cours 🚀</p>
        <p
          class="hidden md:block ml-6 text-sm justify-self-center"
          v-if="challenge && sellers && sellers.length > 0 && nextTarget"
        >
          Plus que {{ nextTarget.target - sellers[0].planSalesCount }} ventes pour {{ sellers[0].name }} pour débloquer
          <span class="font-bold">{{ formatPrice(nextTarget.reward) }}</span>
        </p>
        <span class="hidden md:block text-md font-bold absolute right-4" aria-hidden="true">&rarr;</span>
      </div>
    </div>
  </div>

  <div class="shadow overflow-hidden sm:rounded-lg text-center">
    <ChallengeModal
      v-bind:open="this.openModal"
      v-bind:challenge="challenge"
      v-bind:sellers="sellers"
      @close="this.openModal = false"
    />
  </div>
</template>

<script>
import ChallengeModal from "@/components/challenge/ChallengeModal.vue";
import { formatPrice } from "@/utils/price_formatter";

export default {
  components: {
    ChallengeModal,
  },

  data() {
    return {
      openModal: false,
    };
  },

  props: {
    challenge: Object,
    sellers: Object,
  },
  computed: {
    nextTarget() {
      const salesTargets = this.challenge?.salesTargets || [];
      const planSalesCount = this.sellers?.[0]?.planSalesCount || 0;

      return salesTargets.find((t) => t.target > planSalesCount) || salesTargets[salesTargets.length - 1];
    },
    maxTarget() {
      const salesTargets = this.challenge?.salesTargets || [];

      return salesTargets[salesTargets.length - 1]?.target;
    },
  },
  methods: {
    formatPrice,
  },
};
</script>
