<template>
  <div>
    <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">
      {{ $t("Stores") }}
    </h1>
    <div v-if="currentUserHasPermission('admin')" class="flex flex-row-reverse">
      <GenericButton @click="createStore">
        {{ $t("Add a store") }}
      </GenericButton>
    </div>
    <SearchBar
      class="my-4"
      :current-page="currentPage"
      :placeholder="'Recherche de point de vente par nom/url'"
      :initial-keywords="keywords"
      :available-filters="[]"
      :selected-filters="[]"
      @updateKeywordSearch="updateKeywordSearch"
    />
    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table class="min-w-full divide-y divide-gray-300">
        <thead class="bg-gray-50">
          <tr class="hidden md:table-row">
            <th scope="col" class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
              Nom
            </th>
            <th scope="col" class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
              Statut
            </th>
            <th scope="col" class="text-center text-xs font-medium uppercase tracking-wide text-gray-500"></th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white text-gray-900 text-xs font-medium">
          <tr
            v-for="store in stores"
            :key="store.id"
            :class="['cursor-pointer hover:bg-gray-100']"
            @click="redirectToStoreDetailsPage(store.id)"
          >
            <td class="px-6 py-4 hidden md:table-cell">
              <span v-if="isPresent(store.salesChannel)">{{ store.salesChannel }}</span>
              <span v-else-if="isPresent(store.setupBy)" class="text-gray-400">Référent : {{ store.setupBy }}</span>
            </td>
            <td class="px-6 py-4 hidden md:table-cell">
              <FieldSpan
                :style-to-add="getStoreStatusFieldDisplayStyle(storeStatusOrSetup(store))"
                :value="storeStatusOrSetup(store)"
              />
            </td>
            <td class="w-10">
              <TrashIcon
                class="h-6 w-6"
                aria-hidden="true"
                v-if="store.status === 'setup'"
                @click.stop="openDisableStoreModal(store.id)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination
      :currentPage="currentPage"
      :pages="pages"
      :startIdx="startIdx"
      :endIdx="endIdx"
      :total="totalNumberOfStores"
      v-on:changePage="handleChangePage"
      v-if="pages.length > 1"
    />
    <DisableModal
      :open="disableStoreModalOpen"
      :storeId="disableStoreId"
      @closeDisableStoreModal="disableStoreModalOpen = false"
      @disableStore="disableStore"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import StoreService from "@/api/services/store";
import Pagination from "@/components/utils/Pagination.vue";
import SearchBar from "@/components/menu/SearchBar.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import DisableModal from "@/components/store/DisableModal.vue";
import FieldSpan from "@/components/utils/FieldSpan.vue";
import { TrashIcon } from "@heroicons/vue/solid";
import { isPresent } from "@/utils/validation";

const PAGE_SIZE = 25;

export default {
  components: {
    SearchBar,
    Pagination,
    GenericButton,
    DisableModal,
    FieldSpan,
    TrashIcon,
  },

  async mounted() {
    await this.retrieveStores(this.currentPage, this.keywords);
  },

  data() {
    return {
      stores: [],
      currentPage: 1,
      keywords: null,
      totalNumberOfStores: 1,
      disableStoreModalOpen: false,
      disableStoreId: null,
    };
  },

  computed: {
    ...mapState("store", ["isLoading"]),
    ...mapGetters("auth", ["currentUserHasPermission"]),

    startIdx() {
      return (this.currentPage - 1) * PAGE_SIZE;
    },

    endIdx() {
      return Math.min(this.startIdx + PAGE_SIZE, this.totalNumberOfStores);
    },

    pages() {
      if (this.isLoading || this.totalNumberOfStores < PAGE_SIZE) {
        return [];
      }
      return [...Array(Math.ceil(this.totalNumberOfStores / PAGE_SIZE)).keys()].map((e) => e + 1);
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapMutations("store", ["setIsLoading"]),
    isPresent,

    async retrieveStores(page, salesChannel) {
      const response = await StoreService.getStores({ page: page, sales_channel: salesChannel });
      this.stores = response.data.stores;
      this.totalNumberOfStores = response.data.totalNumberOfStores;
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.retrieveStores(page, this.keywords);
    },

    updateKeywordSearch(keywords) {
      this.keywords = keywords;
      this.handleChangePage(1);
    },

    async createStore() {
      try {
        await StoreService.createStore();
        this.notify({
          category: "simple",
          type: "success",
          title: "Nouveau point de vente créé",
          text: "Vous pouvez maintenant compléter les informations de votre point de vente",
        });
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur s'est produite",
          text: error.response?.data?.error || error.message,
        });
      }
      await this.retrieveStores(1, null);
    },

    storeStatusOrSetup(store) {
      return store.setupStatus || store.status;
    },

    getStoreStatusFieldDisplayStyle(status) {
      switch (status) {
        case "active":
          return "bg-green-100 text-green-800";
        case "inactive":
          return "bg-red-100 text-red-800";
        case "pending_verification":
          return "bg-blue-100 text-blue-800";
        case "information_to_be_completed":
          return "bg-yellow-100 text-yellow-800";
      }
    },

    redirectToStoreDetailsPage(storeId) {
      this.$router.push(`/admin/stores/${storeId}`);
    },

    isStoreSetup(store) {
      return store.status === "setup";
    },

    openDisableStoreModal(storeId) {
      this.disableStoreId = storeId;
      this.disableStoreModalOpen = true;
    },

    async disableStore(storeId) {
      this.disableStoreModalOpen = false;
      try {
        this.setIsLoading(true);
        await StoreService.updateStoreStatus(storeId, "inactive");
        await this.notify({
          category: "simple",
          type: "success",
          title: "Mise à jour effectuée",
        });
      } catch (error) {
        console.error(error);
        await this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur est survenue",
          text: error.response?.data?.error || error.message,
        });
      } finally {
        this.setIsLoading(false);
        await this.retrieveStores(this.currentPage, this.keywords);
      }
    },
  },
};
</script>
