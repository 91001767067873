<template>
  <div>
    <PlanSaleListTitle />
    <PlanSaleListMenu @updatePage="this.updatePage" @exportCSV="this.downloadCSVReport" />
    <PlanSaleList
      v-bind:current-page="this.currentPage"
      v-bind:plan-sales="this.planSales"
      v-bind:total-number-of-plan-sales="this.totalNumberOfPlanSales"
      v-bind:is-admin="true"
      @updatePage="this.updatePage"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import PlanSaleList from "@/components/plansales/PlanSaleList.vue";
import PlanSaleListTitle from "@/components/plansales/PlanSaleListTitle.vue";
import PlanSaleListMenu from "@/components/plansales/PlanSaleListMenu.vue";

export default {
  components: {
    PlanSaleListTitle,
    PlanSaleList,
    PlanSaleListMenu,
  },

  data: () => {
    return {
      currentPage: 1,
    };
  },

  computed: {
    ...mapState("auth", ["signedIn"]),
    ...mapState("store", ["isLoading"]),
    ...mapState("plan_sale_list", ["planSales", "totalNumberOfPlanSales"]),
  },

  created() {
    if (this.signedIn) {
      this.retrievePlanSales();
    } else {
      this.$router.push("/signin");
    }
  },

  methods: {
    ...mapMutations("store", ["setIsLoading"]),
    ...mapActions("plan_sale_list", ["getPlanSales", "resetFilters", "getPlanSaleCSVExport"]),
    ...mapGetters("plan_sale_list", ["getDateRange"]),

    retrievePlanSales: async function () {
      try {
        this.setIsLoading(true);
        this.resetFilters();
        await this.getPlanSales({
          page: this.currentPage,
          isAdmin: true,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.setIsLoading(false);
      }
    },

    updatePage(page, refresh = true) {
      this.currentPage = page;

      if (refresh) {
        this.retrievePlanSales();
      }
    },

    async downloadCSVReport() {
      const response = await this.getPlanSaleCSVExport();

      const url = window.URL.createObjectURL(new Blob([response.data.csv_data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.filename);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
