import { securedAxiosInstance } from "../axios";
import { toSnakeCase } from "@/utils/utils";

class ClaimService {
  addInvoiceToClaim(claim, costItems, invoiceFile) {
    let formData = new FormData();
    formData.append("invoice_file", invoiceFile);

    costItems
      .map((item) => toSnakeCase(item))
      .forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          formData.append(`cost_items[${index}][${key}]`, item[key]);
        });
      });

    return securedAxiosInstance.post(`/claims/${claim.friendlyId}/invoices`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  impossibleQuotationUpload(claim, message, reason) {
    return securedAxiosInstance.post(`/claims/${claim.friendlyId}/quotations`, {
      message: message,
      reason: reason,
    });
  }

  addQuotationToClaim(claim, costItems, quotationFile, productState) {
    let formData = new FormData();
    formData.append("quotation_file", quotationFile);

    costItems
      .map((item) => toSnakeCase(item))
      .forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          formData.append(`cost_items[${index}][${key}]`, item[key]);
        });
      });

    if (productState) {
      formData.append("product_state", productState);
    }

    return securedAxiosInstance.post(`/claims/${claim.friendlyId}/quotations`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getClaimByFriendlyId(friendlyId) {
    return securedAxiosInstance.get(`/claims/${friendlyId}`);
  }
}

export default new ClaimService();
