<template>
  <th
    :class="['col-span-1 px-3 py-3.5 text-left text-xs font-body-bold text-gray-900 cursor-pointer', customClass]"
    :style="borderStyle"
    @click="emitSort"
  >
    <slot></slot>
    <span v-if="isSortedAsc">↑</span>
    <span v-if="isSortedDesc">↓</span>
  </th>
</template>

<script>
export default {
  props: {
    sortColumn: String,
    sortOrder: String,
    columnKey: String,
    borderStyle: String,
    customClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    isSortedAsc() {
      return this.sortColumn === this.columnKey && this.sortOrder === "asc";
    },
    isSortedDesc() {
      return this.sortColumn === this.columnKey && this.sortOrder === "desc";
    },
  },
  methods: {
    emitSort() {
      this.$emit("sort", this.columnKey);
    },
  },
};
</script>
