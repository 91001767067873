<template>
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">
        {{ $t("Products") }}
      </h1>
    </div>
    <span class="relative z-0 inline-flex shadow-sm rounded-md space-x-3">
      <button
        v-if="this.anyProductSelected"
        type="button"
        @click="this.uploadProductsToPlatform"
        class="relative inline-flex items-center px-4 py-2 py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-button-font-primary bg-primary transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      >
        {{ $t("Upload to platform") }}
      </button>
      <button
        v-if="this.anyProductSelected"
        type="button"
        @click="this.removeProductsFromPlatform"
        class="-ml-px relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-button-font-primary bg-primary transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      >
        {{ $t("Remove from platform") }}
      </button>
    </span>
  </div>
</template>

<script>
export default {
  props: ["selectedProducts"],
  computed: {
    noProductSelected: function () {
      return this.selectedProducts.length === 0;
    },
    anyProductSelected: function () {
      return this.selectedProducts.length > 0;
    },
  },
  methods: {
    uploadProductsToPlatform() {
      this.$emit("upload", this.selectedProducts);
    },
    removeProductsFromPlatform() {
      this.$emit("remove", this.selectedProducts);
    },
  },
};
</script>
