<template>
  <div>
    <div class="flex items-start my-6">
      <div class="flex flex-col w-full">
        <div class="sm:w-1/3 flex justify-between">
          <p class="font-body-bold text-lg">{{ $t("Select a time range") }}</p>
          <button @click="resetToBeginning" class="text-primary-dark underline text-xs">Depuis le début</button>
        </div>
        <div class="flex mb-2 w-full sm:w-1/3 flex-col">
          <Datepicker
            class="text-sm rounded-md"
            v-model="date"
            range
            multiCalendars
            v-bind:presetRanges="presetRanges"
            autoApply
            v-bind:enableTimePicker="false"
            v-bind:format="datePickerFormat"
            locale="fr"
            :max-date="new Date()"
            :esc-close="false"
          />
        </div>
      </div>
    </div>
    <SellerAnalytics
      v-if="stats && selectedStoreId"
      :sellerStats="stats"
      :totalStats="totalStats"
      @sortChanged="onSellersSortChange"
      @storeIdSelected="onStoreIdSelected"
    />
    <StoreAnalytics
      v-else-if="stats"
      :storeStats="stats"
      :totalStats="totalStats"
      @sortChanged="onStoresSortChange"
      @storeIdSelected="onStoreIdSelected"
    />
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import StoreAnalytics from "@/components/analytics/commission/StoreAnalytics.vue";
import SellerAnalytics from "@/components/analytics/commission/SellerAnalytics.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { endOfMonth, startOfMonth, subMonths, startOfWeek, subWeeks, endOfWeek, format } from "date-fns";
import { mapActions, mapMutations, mapState } from "vuex";
import AnalyticsService from "@/api/services/analytics";
import { currentMonth, previousMonth, currentWeek, previousWeek } from "@/utils/date";
import { formatResponse } from "@/utils/backend_response_formatter";

export default {
  components: {
    Datepicker,
    StoreAnalytics,
    SellerAnalytics,
  },

  created() {
    const endDate = endOfMonth(new Date());
    const startDate = startOfMonth(new Date());
    this.date = [startDate, endDate];
  },

  data: () => {
    return {
      selectedStoreId: null,
      stats: null,
      totalStats: null,
      storesSortParams: {
        column: null,
        order: null,
      },
      sellersSortParams: {
        column: null,
        order: null,
      },
      date: [],
      presetRanges: [
        {
          id: 1,
          label: "Mois en cours",
          range: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
          id: 2,
          label: "Semaine en cours",
          range: [startOfWeek(new Date(), { weekStartsOn: 1 }), endOfWeek(new Date(), { weekStartsOn: 1 })],
        },
        {
          id: 3,
          label: "Mois précédent",
          range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
        },
        {
          id: 4,
          label: "Semaine précédente",
          range: [
            subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
            subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1),
          ],
        },
      ],
      comparedTo: {},
    };
  },

  computed: {
    ...mapState("auth", ["signedIn"]),
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapMutations("store", ["setIsLoading"]),

    datePickerFormat(date) {
      const startDate = date[0];
      const endDate = date[1];

      const startDay = startDate.getDate();
      const startMonth = startDate.getMonth() + 1;
      const startYear = startDate.getFullYear();

      const endDay = endDate.getDate();
      const endMonth = endDate.getMonth() + 1;
      const endYear = endDate.getFullYear();

      if (startDate && endDate) {
        if (startDate instanceof Date && endDate instanceof Date) {
          if (currentMonth(startDate, endDate)) {
            return "Mois en cours";
          }
          if (previousMonth(startDate, endDate)) {
            return "Mois précédent";
          }
          if (currentWeek(startDate, endDate)) {
            return "Semaine en cours";
          }
          if (previousWeek(startDate, endDate)) {
            return "Semaine précédente";
          }
        }
      }

      return `From ${startDay}/${startMonth}/${startYear} to ${endDay}/${endMonth}/${endYear}`;
    },

    async getCommissionsAnalytics() {
      this.setIsLoading(true);
      try {
        const start = Array.isArray(this.date) && this.date[0] ? format(this.date[0], "yyyy-MM-dd") : null;
        const end = Array.isArray(this.date) && this.date[1] ? format(this.date[1], "yyyy-MM-dd") : null;
        const sortParams = this.selectedStoreId ? this.sellersSortParams : this.storesSortParams;

        const response = this.selectedStoreId
          ? await AnalyticsService.getCommissionSellersData(this.selectedStoreId, start, end, sortParams)
          : await AnalyticsService.getCommissionSalesData(start, end, sortParams);

        const formattedResponse = formatResponse(response.data);

        this.stats = formattedResponse.stats;
        this.totalStats = formattedResponse.total;
      } catch (error) {
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },

    onStoreIdSelected(storeId) {
      this.selectedStoreId = storeId;
      this.getCommissionsAnalytics();
    },

    onStoresSortChange: function (sortParams) {
      this.storesSortParams = sortParams;
      this.getCommissionsAnalytics();
    },

    onSellersSortChange: function (sortParams) {
      this.sellersSortParams = sortParams;
      this.getCommissionsAnalytics();
    },

    resetToBeginning() {
      this.date = null;
    },
  },

  watch: {
    date: function (newDate, oldDate) {
      if (JSON.stringify(newDate) !== JSON.stringify(oldDate)) {
        this.getCommissionsAnalytics();
      }
    },
  },
};
</script>
