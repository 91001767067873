<template>
  <div>
    <PlanSaleListTitle />
    <PlanSaleListMenu @updatePage="this.updatePage" />
    <PlanSaleList
      v-bind:current-page="this.currentPage"
      v-bind:plan-sales="this.planSales"
      v-bind:total-number-of-plan-sales="this.totalNumberOfPlanSales"
      v-bind:is-admin="false"
      @updatePage="this.updatePage"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import PlanSaleList from "@/components/plansales/PlanSaleList.vue";
import PlanSaleListTitle from "@/components/plansales/PlanSaleListTitle.vue";
import PlanSaleListMenu from "@/components/plansales/PlanSaleListMenu.vue";

export default {
  components: {
    PlanSaleListTitle,
    PlanSaleList,
    PlanSaleListMenu,
  },

  data: () => {
    return {
      currentPage: 1,
    };
  },

  computed: {
    ...mapState("auth", ["signedIn"]),
    ...mapState("store", ["isLoading", "selectedStoreId"]),
    ...mapState("plan_sale_list", ["planSales", "totalNumberOfPlanSales"]),
  },

  created() {
    if (this.signedIn) {
      this.resetFilters();
      this.retrievePlanSales();
    } else {
      this.$router.push("/signin");
    }
  },

  methods: {
    ...mapMutations("store", ["setIsLoading"]),
    ...mapActions("plan_sale_list", ["getPlanSales", "resetFilters"]),

    retrievePlanSales: async function () {
      try {
        this.setIsLoading(true);
        await this.getPlanSales({
          page: this.currentPage,
          isAdmin: false,
          storeId: this.selectedStoreId,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.setIsLoading(false);
      }
    },

    updatePage(page, refresh = true) {
      this.currentPage = page;

      if (refresh) {
        this.retrievePlanSales();
      }
    },
  },
};
</script>
