function isNull(input) {
  return input == null;
}

function isEmpty(input) {
  return (
    (!input && input !== 0) ||
    input.length === 0 ||
    (Object.getPrototypeOf(input) === Object.prototype && Object.getOwnPropertyNames(input).length === 0)
  );
}

function isBlank(input) {
  return isNull(input) || isEmpty(input);
}

/*
isPresent(undefined) === false &&
isPresent(null) === false &&
isPresent("") === false &&
isPresent([]) === false &&
isPresent({}) === false &&
isPresent(0) === true &&
isPresent(1) === true &&
isPresent("Hello") === true &&
isPresent([1, 2, 3]) === true &&
isPresent({ name: "John" }) === true
*/
export function isPresent(input) {
  return !isBlank(input);
}

export function isStoreIdValid(storeId) {
  return isPresent(storeId) && storeId !== "0" && storeId !== 0;
}
