<template>
  <div>
    <div class="bg-yellow-50 border-l-4 border-yellow-400 text-yellow-700 p-4 mt-5 mb-4 sm:mt-6">
      <div class="flex flex-row items-center">
        <ExclamationIcon class="w-8 h-8 text-yellow-400" />
        <p class="ml-4 font-bold">Une action est requise de votre part.</p>
      </div>
      <div class="pl-12">
        <div>
          <div class="flex flex-row items-center justify-between">
            <div>
              <div>
                <p>{{ requiredActionText }}</p>
              </div>
            </div>
            <button
              class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-button-font-primary bg-primary transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-primary"
              @click="openModal = true"
            >
              {{ requiredActionButtonText }}
            </button>
          </div>
        </div>
        <div class="shadow overflow-hidden sm:rounded-lg text-center">
          <InvoiceUploadModal
            v-if="isInvoiceUploadRequired"
            :open="openModal"
            :claim="claim"
            @close="openModal = false"
          />
          <QuotationUploadModal
            v-else-if="isQuotationUploadRequired"
            :open="openModal"
            :claim="claim"
            @close="openModal = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from "@heroicons/vue/solid";
import InvoiceUploadModal from "@/components/claim/details/information/action/invoices/InvoiceUploadModal.vue";
import QuotationUploadModal from "@/components/claim/details/information/action/quotations/QuotationUploadModal.vue";

export default {
  components: {
    QuotationUploadModal,
    InvoiceUploadModal,
    ExclamationIcon,
  },

  props: ["claim"],

  data() {
    return {
      openModal: false,
    };
  },

  computed: {
    requiredActionButtonText() {
      if (this.isInvoiceUploadRequired) {
        return this.$t("Upload an invoice");
      } else if (this.isQuotationUploadRequired) {
        return this.$t("Upload a quotation");
      } else {
        return "";
      }
    },
    requiredActionText() {
      if (this.isInvoiceUploadRequired) {
        return "Veuillez télécharger la facture de gestion du sinistre.";
      } else if (this.isQuotationUploadRequired) {
        return "Veuillez télécharger le devis de gestion du sinistre.";
      } else {
        return "";
      }
    },
    isInvoiceUploadRequired() {
      return this.claim.requiredAction === "invoice_upload_required";
    },
    isQuotationUploadRequired() {
      return this.claim.requiredAction === "quotation_repair_upload_required";
    },
  },
};
</script>
