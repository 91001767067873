<template>
  <div class="mt-6">
    <div class="p-6 bg-gray-100 rounded-xl">
      <div class="flex flex-col items-center gap-2">
        <CheckCircleIcon class="h-8 w-8 text-green-400" />
        <p class="text-center font-bold text-lg">
          {{ $t("everything is good !") }}
        </p>
        <p class="text-md text-center">
          {{ $t("The sale of the insurance is registered.") }}
        </p>
        <p class="text-md text-center">
          {{ $t("We have sent the customer a confirmation email to this address") }}
          <span class="font-bold">{{ customer.email }}</span>
          .
        </p>
        <div class="rounded-md bg-gray-200 p-2 py-4">
          <div class="flex">
            <div class="ml-3 flex-1">
              <p class="text-xs text-black-700 italic">
                {{
                  $t(
                    "In the case of SEPA payment by RIB registration, payment processing generally takes 3 to 5 working days. The customer will receive the membership confirmation e-mail once the payment has been processed, and the sale will appear on your space at that time."
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/outline";

export default {
  components: {
    CheckCircleIcon,
  },

  props: {
    customer: Object,
  },
};
</script>
