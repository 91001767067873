<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">
        {{ $t("Users") }}
      </h1>
      <div>
        <GenericButton @onClick="this.addUserModalOpen = true">
          {{ $t("Add a user") }}
        </GenericButton>
        <Modal :open="this.addUserModalOpen" @close="this.addUserModalOpen = false">
          <UserForm @success="userCreated" />
        </Modal>
      </div>
    </div>
    <SearchBar
      class="my-4"
      :current-page="this.currentPage"
      :placeholder="'Recherche par nom/email'"
      :initial-keywords="this.keywords"
      :available-filters="[]"
      :selected-filters="[]"
      @updateKeywordSearch="this.updateKeywordSearch"
    />
    <div class="my-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr class="hidden md:table-row">
                <th
                  scope="col"
                  class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                >
                  {{ $t("Last Name") }}
                </th>
                <th
                  scope="col"
                  class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                >
                  {{ $t("Email") }}
                </th>
                <th
                  scope="col"
                  class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                >
                  {{ $t("Selling points") }}
                </th>
                <th
                  scope="col"
                  class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                >
                  {{ $t("Status") }}
                </th>
                <th
                  scope="col"
                  class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                >
                  {{ $t("Role") }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr
                v-for="profile in this.profiles"
                :key="profileKey(profile)"
                :class="['cursor-pointer hover:bg-gray-100']"
              >
                <td class="whitespace-nowrap py-4 pl-4 pr-2 text-xs font-medium text-gray-900 sm:pl-4">
                  {{ profile.name }}
                </td>
                <td class="whitespace-nowrap py-4 pl-4 pr-2 text-xs font-medium text-gray-900 sm:pl-4">
                  {{ profile.email }}
                </td>
                <td class="whitespace-nowrap py-4 pl-4 pr-2 text-xs font-medium text-gray-900 sm:pl-4">
                  {{ profile.salesChannels.slice(0, 2).join(", ") }}
                  <template v-if="profile.salesChannels.length > 2">
                    <i @click="this.showProfileStores = profileKey(profile)">
                      , +{{ profile.salesChannels.length - 2 }} autres
                    </i>
                  </template>
                </td>
                <td class="whitespace-nowrap py-4 pl-4 pr-2 text-xs font-medium text-gray-900 sm:pl-4">
                  <FieldSpan
                    :style-to-add="['text-white', profile.activated ? 'bg-green-400' : 'bg-red-400']"
                    :value="profile.activated ? 'Actif' : 'Inactif'"
                  />
                </td>
                <td class="whitespace-nowrap py-4 pl-4 pr-2 text-xs font-medium text-gray-900 sm:pl-4">
                  <FieldSpan
                    :style-to-add="'bg-blue-400 text-white'"
                    :value="profile.role"
                    :translation-prefix="'roles'"
                  />
                </td>
                <td>
                  <TrashIcon class="h-5 cursor-pointer" @click="deleteProfile(profile)" />
                </td>
              </tr>
            </tbody>
          </table>
          <Modal :open="this.showProfileStores !== null" @close="this.showProfileStores = null">
            <ul>
              <li v-for="channel in this.profileStores" :key="channel">
                {{ channel }}
              </li>
            </ul>
          </Modal>
        </div>
      </div>
    </div>
    <Pagination
      :currentPage="currentPage"
      :pages="pages"
      :startIdx="startIdx"
      :endIdx="endIdx"
      :total="this.totalNumberOfProfiles"
      v-on:changePage="handleChangePage"
      v-if="pages.length > 1"
    />
  </div>
</template>
<script>
import { TrashIcon } from "@heroicons/vue/outline";
import FieldSpan from "@/components/utils/FieldSpan.vue";
import Modal from "@/components/modal/Modal.vue";
import { mapActions, mapState } from "vuex";
import UserService from "@/api/services/user";
import Pagination from "@/components/utils/Pagination.vue";
import SearchBar from "@/components/menu/SearchBar.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import UserForm from "@/components/UserForm.vue";

const PAGE_SIZE = 25;

export default {
  components: {
    TrashIcon,
    Pagination,
    SearchBar,
    FieldSpan,
    GenericButton,
    Modal,
    UserForm,
  },
  async mounted() {
    await this.retrieveStores(this.currentPage, this.keywords);
  },
  data() {
    return {
      profiles: [],
      currentPage: 1,
      keywords: null,
      totalNumberOfProfiles: 1,
      showProfileStores: null,
      addUserModalOpen: false,
    };
  },
  computed: {
    ...mapState("store", ["isLoading"]),
    startIdx() {
      return (this.currentPage - 1) * PAGE_SIZE;
    },
    endIdx() {
      return Math.min(this.startIdx + PAGE_SIZE, this.totalNumberOfProfiles);
    },
    pages() {
      if (this.isLoading || this.totalNumberOfProfiles < PAGE_SIZE) {
        return [];
      }
      return [...Array(Math.ceil(this.totalNumberOfProfiles / PAGE_SIZE)).keys()].map((e) => e + 1);
    },
    profileStores() {
      if (this.showProfileStores) {
        return this.profiles.find((profile) => this.profileKey(profile) === this.showProfileStores).salesChannels;
      }
      return [];
    },
  },
  methods: {
    ...mapActions("notifications", ["notify"]),

    async retrieveStores(page, keywords) {
      const response = await UserService.getProfiles(page, keywords);
      this.profiles = response.data.profiles;
      this.totalNumberOfProfiles = response.data.totalNumberOfProfiles;
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.retrieveStores(page, this.keywords);
    },
    updateKeywordSearch(keywords) {
      this.keywords = keywords;
      this.handleChangePage(1);
    },
    profileKey(profile) {
      // id may not be unique because profiles merge OrganizationManagers and StoreManagers
      return profile.id + profile.role;
    },
    async userCreated() {
      this.addUserModalOpen = false;
      await this.reloadProfiles();
    },
    async deleteProfile(profile) {
      if (!confirm(`Supprimer ${profile.name} ?`)) return;

      try {
        await UserService.deleteProfile(profile.id, profile.type);
        await this.reloadProfiles();
        await this.notify({
          category: "simple",
          type: "success",
          title: "Utilisateur supprimé",
        });
      } catch (error) {
        console.error(error);
        await this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur est survenue",
          text: error.response?.data?.error || error.message,
        });
      }
    },
    async reloadProfiles() {
      this.keywords = null;
      this.currentPage = 1;
      await this.retrieveStores(this.currentPage, this.keywords);
    },
  },
};
</script>
