<template>
  <Datepicker
    :placeholder="placeholder"
    class="text-sm w-full rounded-md"
    v-model="internalValue"
    range
    multiCalendars
    :presetRanges="presetRanges"
    autoApply
    :enableTimePicker="false"
    :format="datePickerFormat"
    locale="fr"
    :max-date="new Date()"
  />
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek, subMonths, subWeeks, format } from "date-fns";
import { currentMonthRange, currentMonth, previousMonth, currentWeek, previousWeek } from "@/utils/date";

export default {
  components: { Datepicker },

  props: {
    placeholder: { type: String },
    modelValue: {
      type: Array,
      required: true,
    },
  },

  data: () => {
    return {
      presetRanges: [
        {
          id: 1,
          label: "Mois en cours",
          range: currentMonthRange(),
        },
        {
          id: 2,
          label: "Semaine en cours",
          range: [startOfWeek(new Date(), { weekStartsOn: 1 }), endOfWeek(new Date(), { weekStartsOn: 1 })],
        },
        {
          id: 3,
          label: "Mois précédent",
          range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
        },
        {
          id: 4,
          label: "Semaine précédente",
          range: [
            subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
            subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1),
          ],
        },
        {
          id: 5,
          label: "3 derniers mois",
          range: [new Date().setMonth(new Date().getMonth() - 3), new Date()],
        },
      ],
    };
  },

  emits: ["update:modelValue"],

  computed: {
    internalValue: {
      get() {
        return this.modelValue?.every((el) => el == null) ? null : this.modelValue;
      },
      set(value) {
        if (value) {
          this.$emit("update:modelValue", format(value[0], "yyyy-MM-dd"), format(value[1], "yyyy-MM-dd"));
        } else {
          this.$emit("update:modelValue", null, null);
        }
      },
    },
  },

  methods: {
    datePickerFormat(date) {
      const startDate = date[0];
      const endDate = date[1];
      if (startDate == null || endDate == null) return "";

      const startDay = startDate.getDate();
      const startMonth = startDate.getMonth() + 1;
      const startYear = startDate.getFullYear();

      const endDay = endDate.getDate();
      const endMonth = endDate.getMonth() + 1;
      const endYear = endDate.getFullYear();

      if (startDate instanceof Date && endDate instanceof Date) {
        if (currentMonth(startDate, endDate)) return "Mois en cours";
        if (previousMonth(startDate, endDate)) return "Mois précédent";
        if (currentWeek(startDate, endDate)) return "Semaine en cours";
        if (previousWeek(startDate, endDate)) return "Semaine précédente";
      }

      return `Du ${startDay}/${startMonth}/${startYear} au ${endDay}/${endMonth}/${endYear}`;
    },
  },
};
</script>
