<template>
  <ClaimListTitle />
  <SearchBar
    v-bind:title="$t('Claim filters')"
    v-bind:placeholder="$t('SearchClaims')"
    v-bind:current-page="currentPage"
    v-bind:available-filters="availableFilters"
    v-bind:selected-filters="selectedFilters"
    v-bind:initial-keywords="keywords"
    @updateFilterSearch="updateFilterSearch"
    @updateKeywordSearch="updateKeywordSearch"
    class="my-4"
  />
  <PaginatedClaimList
    v-bind:total-number-of-claims="totalNumberOfClaims"
    v-bind:current-page="currentPage"
    v-bind:claims="claims"
    @changePage="handleChangePage"
    @redirectToClaimDetailsPage="redirectToClaimDetailsPage"
  />
</template>

<script>
import PaginatedClaimList from "@/components/claim/list/PaginatedClaimList.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import SearchBar from "@/components/menu/SearchBar.vue";
import ClaimListTitle from "@/components/claim/list/ClaimListTitle.vue";

export default {
  components: {
    ClaimListTitle,
    SearchBar,
    PaginatedClaimList,
  },

  data() {
    return {
      currentPage: 1,
      totalNumberOfClaims: null,
    };
  },

  computed: {
    ...mapState("store", ["selectedStoreId"]),
    ...mapGetters("claim_list", ["getClaims"]),
    ...mapState("claim_list", ["claims", "totalNumberOfClaims", "availableFilters", "selectedFilters", "keywords"]),
  },

  methods: {
    ...mapActions("claim_list", ["fetchClaims", "updateFilter", "updateSearch", "restrictStore"]),

    handleChangePage: function (page) {
      this.currentPage = page;
    },

    redirectToClaimDetailsPage: function (claimId) {
      this.$router.push(`/platform/claim/${claimId}`);
    },

    reloadClaims() {
      this.fetchClaims({ page: this.currentPage });
    },

    updateFilterSearch: function (filterName, filterOptions) {
      this.updatePage(1, false);
      this.updateFilter({
        filterName: filterName,
        filterOptions: filterOptions,
        page: this.currentPage,
      });
    },

    updateKeywordSearch: function (keyword) {
      this.updatePage(1, false);
      this.updateSearch({
        searchString: keyword,
        page: this.currentPage,
      });
    },

    updatePage: function (newPage, refreshClaims = true) {
      this.currentPage = newPage;

      if (refreshClaims) {
        this.reloadClaims();
      }
    },
  },

  watch: {
    currentPage() {
      this.reloadClaims();
    },
    selectedStoreId(newSelectedStoreId) {
      if (newSelectedStoreId !== 0) {
        this.restrictStore(newSelectedStoreId);
        this.reloadClaims();
      }
    },
  },

  beforeMount() {
    this.restrictStore(this.$store.state.store.selectedStoreId);
    this.reloadClaims();
  },
};
</script>
