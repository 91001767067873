<template>
  <div class="bg-white shadow sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ product.title }}
      </h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">{{ $t("by") }} {{ product.vendor }}</p>
      <img v-bind:src="product.imageUrl" v-bind:alt="product.title" class="h-1/4 w-1/4" />
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl class="sm:divide-y sm:divide-gray-200">
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">{{ $t("Name") }}</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ product.title }}
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Reference</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ product.referenceId }}
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t("Product Type") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ $t(`${product.productType}`) }}
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">{{ $t("Vendor") }}</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ product.vendor }}
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="inline-flex text-sm font-medium text-gray-500">
            <p>{{ $t("Published") }}</p>
            <NewTooltip class="font-mono" :text="$t('Published field description')" />
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <FieldSpan
              v-bind:style-to-add="getEnabledFieldDisplayStyle(product.enabled)"
              v-bind:value="product.enabled"
            />
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="inline-flex text-sm font-medium text-gray-500">
            <p>{{ $t("Status") }}</p>
            <NewTooltip class="font-mono" :text="$t('Status field description')" />
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <FieldSpan v-bind:style-to-add="getStatusFieldDisplayStyle(product.status)" v-bind:value="product.status" />
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import NewTooltip from "@/components/tooltip/NewTooltip.vue";

export default {
  components: {
    FieldSpan,
    NewTooltip,
  },
  props: ["product"],
  methods: {
    getStatusFieldDisplayStyle: function (status) {
      let result;
      switch (status) {
        case "Pending":
          result = "bg-yellow-100 text-yellow-800";
          break;
        case "Live":
          result = "bg-green-100 text-green-800";
          break;
        case "Matched":
          result = "bg-blue-100 text-blue-800";
          break;
        case "Refused":
          result = "bg-red-100 text-red-800";
          break;
        default:
          result = "bg-blue-100 text-blue-800";
      }
      return result;
    },
    getEnabledFieldDisplayStyle: function (isEnabled) {
      return isEnabled ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800";
    },
  },
};
</script>
