import { securedAxiosInstance } from "../axios";

class SaleService {
  seeContractDetails(productCategoryName, productPrice, storeId) {
    return securedAxiosInstance.get(`/store/${storeId}/plan_sales/simulation`, {
      params: { productPrice, productCategory: productCategoryName },
    });
  }
}

export default new SaleService();
