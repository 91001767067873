<template>
  <div class="flex flex-col">
    <div class="flex gap-4 mt-4 flex-col sm:flex-row">
      <RadioGroup v-model="localSelectedOfferSku">
        <RadioGroupLabel class="text-xl font-heading-1">Type de contrat</RadioGroupLabel>

        <div class="flex gap-4 mt-4 flex-col sm:flex-row">
          <RadioGroupOption
            as="template"
            v-for="offer in offers"
            :key="offer.contract_sku"
            :value="offer.contract_sku"
            v-slot="{ checked, active }"
          >
            <div
              :class="[
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-primary ring-2 ring-primary' : '',
                'relative flex border bg-white p-4 shadow-sm focus:outline-none flex-grow rounded-3xl cursor-pointer',
              ]"
            >
              <span class="flex flex-1">
                <span class="flex flex-col">
                  <span class="block text-md font-medium text-gray-900">{{ offerDisplayTermLength(offer) }}</span>
                  <span class="mt-1 flex items-center text-md text-gray-500">{{ formatPrice(offer.price) }}</span>
                  <span class="mt-1 flex items-center text-xs text-gray-500">{{ offer.sku }}</span>
                </span>
              </span>
              <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-primary-dark']" aria-hidden="true" />
              <span
                :class="[
                  active ? 'border' : 'border-2',
                  checked ? 'border-primary' : 'border-transparent',
                  'pointer-events-none absolute -inset-px rounded-3xl cursor-pointer',
                ]"
                aria-hidden="true"
              />
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </div>
  </div>
</template>

<script>
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { CheckCircleIcon } from "@heroicons/vue/outline";
import { formatPrice } from "@/utils/price_formatter";

export default {
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    CheckCircleIcon,
  },

  props: {
    offers: Array,
    selectedOfferSku: String,
  },

  data() {
    return {
      localSelectedOfferSku: this.selectedOfferSku,
    };
  },

  watch: {
    localSelectedOfferSku(newVal) {
      this.$emit("update:selectedOfferSku", newVal);
    },

    selectedOfferSku(newVal) {
      this.localSelectedOfferSku = newVal;
    },
  },

  methods: {
    formatPrice,

    offerDisplayTermLength(offer) {
      return offer.recurring
        ? "Paiement mensuel"
        : `${offer.term_length / 12} an${offer.term_length / 12 > 1 ? "s" : ""}`;
    },
  },
};
</script>
