<template>
  <div>
    <RetailAnalytics />
  </div>
</template>

<script>
import RetailAnalytics from "@/views/pos/analytics/RetailAnalytics.vue";

export default {
  components: {
    RetailAnalytics,
  },
};
</script>
