import { plainAxiosInstance, securedAxiosInstance } from "../axios";
import { NEW_PASSWORD_URL } from "./constants";
import { toSnakeCase } from "@/utils/utils.js";

class UserService {
  getUser() {
    return plainAxiosInstance.get("/user");
  }

  updatePassword({ oldPassword, newPassword, newPasswordConfirmation }) {
    return securedAxiosInstance.put(NEW_PASSWORD_URL, {
      old_password: oldPassword,
      password: newPassword,
      password_confirmation: newPasswordConfirmation,
    });
  }

  updateProfile(params) {
    return securedAxiosInstance.put("/profile", params);
  }

  forgotPassword(email) {
    return plainAxiosInstance.get("/user/password_resets/new", {
      params: { email },
    });
  }

  getProfiles(page, keywords) {
    return securedAxiosInstance.get("/profiles", { params: { page, keywords } });
  }

  createUser(data) {
    return securedAxiosInstance.post("/profile", { user: toSnakeCase(data) });
  }

  newUser() {
    return securedAxiosInstance.get("/profile/new");
  }

  deleteProfile(id, type) {
    return securedAxiosInstance.delete("/profile", { params: { id, type } });
  }
}

export default new UserService();
