<template>
  <div class="flex justify-between px-2 my-4">
    <h1 class="text-4xl font-heading-1 tracking-tight text-gray-900">
      {{ $t("Sellers") }}
    </h1>
    <GenericButton @onClick="isSellerCreationOpen = true">{{ $t("Add") }}</GenericButton>
  </div>
  <SearchBar
    class="my-4"
    v-bind:placeholder="$t('SearchSellers')"
    v-bind:current-page="currentPage"
    v-bind:available-filters="{}"
    v-bind:selected-filters="{}"
    v-bind:initial-keywords="this.keywords"
    @updateKeywordSearch="this.updateKeywordSearch"
  />
  <SellerList v-bind:sellers="sellers" @getSellers="getSellers('')" />
  <Pagination
    v-bind:currentPage="currentPage"
    v-bind:pages="pages"
    v-bind:startIdx="startIdx"
    v-bind:endIdx="endIdx"
    v-bind:total="totalNumberOfSellers"
    v-on:changePage="handleChangePage"
    v-if="pages.length > 1"
  />
  <SellerCreation
    v-bind:isOpen="isSellerCreationOpen"
    @closeSellerCreation="closeSellerCreation"
    @getSellers="getSellers('')"
  />
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import SellerList from "@/components/seller/SellerList.vue";
import Pagination from "@/components/utils/Pagination.vue";
import SearchBar from "@/components/menu/SearchBar.vue";
import SellerCreation from "@/views/pos/seller/SellerCreation.vue";

import StoreService from "@/api/services/store";

const PAGE_SIZE = 25;

export default {
  components: {
    GenericButton,
    SellerList,
    Pagination,
    SearchBar,
    SellerCreation,
  },

  data() {
    return {
      sellers: [],
      totalNumberOfSellers: 0,
      currentPage: 1,
      keywords: "",
      isSellerCreationOpen: false,
    };
  },

  mounted() {
    this.getSellers("");
    if (this.$route.params.openSellerCreation) {
      this.isSellerCreationOpen = true;
    }
  },

  computed: {
    pages: function () {
      if (this.totalNumberOfSellers < PAGE_SIZE) {
        return [];
      }
      return [...Array(Math.ceil(this.totalNumberOfSellers / PAGE_SIZE)).keys()].map((e) => e + 1);
    },

    startIdx: function () {
      return (this.currentPage - 1) * PAGE_SIZE;
    },

    endIdx: function () {
      return Math.min(this.startIdx + PAGE_SIZE, this.totalNumberOfSellers);
    },
  },

  methods: {
    handleChangePage: function (page) {
      this.currentPage = page;
      this.getSellers("");
    },

    updateKeywordSearch: function (search) {
      this.currentPage = 1;
      this.getSellers(search);
    },

    closeSellerCreation: function () {
      this.isSellerCreationOpen = false;
    },

    getSellers: async function (keywords) {
      try {
        const response = await StoreService.getSellers(
          this.$store.state.store.selectedStoreId,
          keywords,
          this.currentPage
        );

        this.sellers = response.data.sellers;
        this.totalNumberOfSellers = response.data.totalNumberOfSellers;
      } catch (error) {
        console.log(error.response);
        this.notify({
          category: "simple",
          type: "error",
        });
      }
    },
  },
};
</script>
