<template>
  <div v-if="costItems" class="p-4">
    <p v-if="maxCoverageAmountReached" class="text-red-600 text-xs mb-5">
      Le total des dépenses de ce devis est supérieur au montant maximal de prise en charge, ce devis risque d'être
      refusé par Estaly.
    </p>
    <div class="text-2xl font-handwriting">
      <div class="flex justify-end text-xs">
        <span>Total HT</span>
        <span class="ml-3">{{ totalBeforeTaxes }} €</span>
      </div>
      <div class="flex justify-end mt-2 text-xs">
        <span>TVA</span>
        <span class="ml-3">{{ taxes }} €</span>
      </div>
      <div class="flex justify-end mt-2 text-xs font-bold">
        <span>Total TTC</span>
        <span class="ml-3">{{ total }} €</span>
      </div>
    </div>
  </div>
</template>

<script>
import { isPresent } from "@/utils/validation";
export default {
  props: ["costItems"],
  computed: {
    maxCoverageAmountReached() {
      return (
        isPresent(this.claim?.coverages?.compensationValue) &&
        isPresent(this.total) &&
        parseFloat(this.claim?.coverages?.compensationValue) < parseFloat(this.total)
      );
    },
    totalBeforeTaxes() {
      return this.costItems
        .reduce((sum, item) => {
          return sum + parseFloat(item.unitPriceBeforeTax ?? 0);
        }, 0)
        .toFixed(2);
    },
    taxes() {
      return (this.total * 0.2).toFixed(2);
    },
    total() {
      return this.costItems
        .reduce((sum, item) => {
          return sum + parseFloat(item.total ?? 0);
        }, 0)
        .toFixed(2);
    },
  },
};
</script>
