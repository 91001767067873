<template>
  <button
    class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-button-font-primary bg-primary uppercase transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-primary"
    @click="triggerClickEvent"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  methods: {
    triggerClickEvent(event) {
      this.$emit("onClick", event);
    },
  },
};
</script>
