<template>
  <div class="mt-8" style="min-height: 400px">
    <p class="text-xl font-heading-1 pb-4">Recueil du consentement</p>
    <div>
      <p>
        Nous avons envoyé au client un code de 4 chiffres par SMS et par email. Veuillez renseigner le code afin de
        finaliser l'adhésion.
      </p>
    </div>
    <div class="px-72 py-20">
      <input
        type="text"
        name="consent-code"
        id="consent-code"
        class="focus:ring-primary focus:border-primary block w-full rounded-lg border-gray-300 text-5xl text-center"
        placeholder="XXXX"
        v-model="consentCode"
      />
    </div>
    <div class="flex justify-center">
      <button
        type="button"
        class="inline-flex items-center rounded-2xl border border-transparent text-button-font-primary bg-primary uppercase px-6 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
        @click="verifyConsentCode"
      >
        Confirmer le paiement
      </button>
    </div>
    <div class="mt-2 flex justify-center">
      <span class="text-primary-dark underline cursor-pointer" @click="sendConsentCode">Renvoyer le code</span>
    </div>
    <div class="mt-2 flex justify-center">
      <span class="text-gray-700 text-sm italic underline cursor-pointer" @click="reportMissingCode">
        Le client n'a pas reçu le code
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      consentCode: "",
    };
  },

  methods: {
    verifyConsentCode() {
      this.$emit("verifyConsentCode", this.consentCode);
    },
    sendConsentCode() {
      this.$emit("sendConsentCode");
    },
    reportMissingCode() {
      this.$emit("reportMissingCode");
    },
  },
};
</script>
