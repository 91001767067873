<template>
  <Modal v-bind:open="this.open" @close="this.close">
    <div>
      <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full text-button-font-primary bg-primary">
        <XCircleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
      </div>
      <div>
        <div class="mt-3 text-center sm:mt-5">
          <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
            <slot name="title"></slot>
          </DialogTitle>
        </div>
      </div>
      <div class="text-center mt-4">
        <p><slot name="text"></slot></p>
      </div>

      <div class="text-center mt-4">
        <GenericButton @onClick="close">{{ $t("Back") }}</GenericButton>
      </div>
    </div>
  </Modal>
</template>

<script>
import { DialogTitle } from "@headlessui/vue";
import { XCircleIcon } from "@heroicons/vue/outline";
import GenericButton from "@/components/utils/GenericButton.vue";
import Modal from "@/components/modal/Modal.vue";
export default {
  components: {
    Modal,
    DialogTitle,
    XCircleIcon,
    GenericButton,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
