<template>
  <div>
    <div>
      <div class="flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr class="hidden md:table-row">
                    <th
                      scope="col"
                      class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {{ $t("Store") }}
                    </th>
                    <th
                      scope="col"
                      class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {{ $t("First name") }}
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {{ $t("Last name") }}
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {{ $t("Email") }}
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {{ $t("Phone") }}
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {{ $t("Complete") }}
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr
                    v-for="(seller, id) in sellers"
                    v-bind:key="id"
                    v-bind:class="['cursor-pointer hover:bg-gray-100']"
                  >
                    <td class="whitespace-nowrap py-4 pl-4 pr-2 text-xs font-medium text-gray-900 sm:pl-4">
                      <div class="flex flex-col">
                        <div class="flex items-center">
                          <span>{{ seller.salesChannel }}</span>
                        </div>
                        <div class="md:hidden font-light">
                          {{ seller.firstName }}
                        </div>
                        <div class="md:hidden font-light">
                          {{ seller.lastName }}
                        </div>
                        <div class="md:hidden font-light">
                          {{ seller.email }}
                        </div>
                        <div class="md:hidden font-light">
                          {{ seller.phone }}
                        </div>
                        <div class="mt-2 md:hidden font-light" v-if="sellerUncomplete(seller)">
                          <GenericButton @onClick="handleSellerUpdate(seller)">
                            <span class="text-xs">{{ $t("Edit") }}</span>
                          </GenericButton>
                        </div>
                      </div>
                    </td>
                    <td class="hidden md:table-cell whitespace-nowrap py-4 pl-4 pr-2 text-xs text-gray-500">
                      {{ seller.firstName }}
                    </td>
                    <td class="hidden md:table-cell whitespace-nowrap py-4 pl-4 pr-2 text-xs text-gray-500">
                      {{ seller.lastName }}
                    </td>
                    <td class="hidden md:table-cell whitespace-nowrap py-4 pl-4 pr-2 text-xs text-gray-500">
                      {{ seller.email }}
                    </td>

                    <td class="hidden md:table-cell whitespace-nowrap py-4 pl-4 pr-2 text-xs text-gray-500">
                      {{ seller.phone }}
                    </td>
                    <td class="hidden md:table-cell whitespace-nowrap py-4 pl-2 pr-2 text-xs text-gray-500">
                      <GenericButton v-if="sellerUncomplete(seller)" @onClick="handleSellerUpdate(seller)">
                        <span class="text-xs">{{ $t("Complete") }}</span>
                      </GenericButton>
                      <div v-else>
                        <CheckIcon class="h-5 w-5 text-green-500" aria-hidden="true" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SellerUpdate
    v-bind:isOpen="isSellerUpdateOpen"
    v-bind:selectedSeller="selectedSeller"
    @closeSellerUpdate="closeSellerUpdate"
    @getSellers="getSellers"
  />
</template>

<script>
import SellerUpdate from "@/views/pos/seller/SellerUpdate.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import { isPresent } from "@/utils/validation";
import { CheckIcon } from "@heroicons/vue/solid";

export default {
  components: {
    GenericButton,
    SellerUpdate,
    CheckIcon,
  },

  data() {
    return {
      isSellerUpdateOpen: false,
      selectedSeller: {},
    };
  },

  props: ["sellers"],

  emits: ["getSellers"],

  methods: {
    sellerUncomplete: function (seller) {
      return (
        !isPresent(seller.firstName) ||
        !isPresent(seller.lastName) ||
        !isPresent(seller.email) ||
        !isPresent(seller.phone)
      );
    },

    closeSellerUpdate: function () {
      this.isSellerUpdateOpen = false;
    },

    handleSellerUpdate: function (seller) {
      this.isSellerUpdateOpen = true;
      this.selectedSeller = seller;
    },

    getSellers: function () {
      this.$emit("getSellers");
    },
  },
};
</script>
