<template>
  <div>
    <div
      v-if="store.setupStatus == 'information_to_be_completed'"
      class="ml-2 border-l-4 border-yellow-400 bg-yellow-50 p-2"
    >
      <h2 class="ml-2 text-yellow-700 text-sm">
        Complétez les informations sur votre magasin pour commencer à proposer Estaly à vos clients.
      </h2>
    </div>
    <div v-if="store.setupStatus == 'pending_verification'" class="ml-2 border-l-4 border-blue-400 bg-blue-50 p-2">
      <h2 class="ml-2 text-blue-700 text-sm">
        Les informations de votre magasin sont complétées. Nos équipes valident votre compte sous peu.
      </h2>
    </div>
    <div v-if="store">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div class="bg-white shadow sm:rounded-lg mt-4 p-6">
          <h3 class="font-bold pb-7">Etapes de votre configuration</h3>
          <div class="space-y-4">
            <button
              v-for="step in steps"
              :key="step.id"
              @click="selectedStepId = step.id"
              class="w-full py-2 px-4 bg-gray-100 text-black rounded hover:bg-gray-200"
              :class="selectedStepId === step.id ? 'bg-gray-200' : 'bg-gray-100'"
            >
              <div class="flex justify-between">
                {{ step.name }}
                <span :class="btnClasses(step)">
                  {{ btnText(step) }}
                </span>
              </div>
            </button>
          </div>
        </div>
        <div class="bg-white shadow sm:rounded-lg mt-4 p-6">
          <div class="flex justify-between">
            <h3 class="font-bold pb-4">{{ selectedStep.name }}</h3>
            <div>
              <span :class="btnClasses(selectedStep)">
                {{ btnText(selectedStep) }}
              </span>
            </div>
          </div>
          <component
            :is="selectedStep.component"
            :configStatus="selectedStep.configStatus"
            :store="store"
            @configurationUpdated="$emit('configurationUpdated')"
            @stepValidated="goToNextStep"
          ></component>
        </div>
      </div>
    </div>
    <div v-else>
      <p>{{ $t("Loading...") }}</p>
    </div>
  </div>
</template>

<script>
import GeneralInformations from "@/views/admin/store/details/setup/GeneralInformations.vue";
import ContactInformations from "@/views/admin/store/details/setup/ContactInformations.vue";
import RepairInformations from "@/views/admin/store/details/setup/RepairInformations.vue";

export default {
  props: ["store"],

  components: {
    GeneralInformations,
    ContactInformations,
    RepairInformations,
  },

  data() {
    return {
      steps: [
        {
          id: "generalInformation",
          configStatus: "generalInformationsConfigStatus",
          name: "Informations générales",
          component: GeneralInformations,
        },
        {
          id: "contactInformation",
          configStatus: "contactInformationsConfigStatus",
          name: "Utilisateurs",
          component: ContactInformations,
        },
        {
          id: "afterSalesService",
          configStatus: "afterSalesServiceConfigStatus",
          name: "Réparations",
          component: RepairInformations,
        },
      ],
      selectedStepId: "generalInformation", // default step on init
      badge: {
        to_be_completed: {
          text: "A compléter",
          classes: "inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-600",
        },
        pending_validation: {
          text: "Complété",
          classes: "inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-800",
        },
        validated: {
          text: "Validé",
          classes: "inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700",
        },
        rejected: {
          text: "Rejeté",
          classes: "inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700",
        },
      },
    };
  },

  computed: {
    selectedStep() {
      return this.steps.find((step) => step.id === this.selectedStepId);
    },

    nextStepId() {
      const currentStepIndex = this.steps.findIndex((step) => step.id === this.selectedStepId);
      if (currentStepIndex !== -1 && currentStepIndex < this.steps.length - 1) {
        return this.steps[currentStepIndex + 1].id;
      }
      return null;
    },
  },

  methods: {
    btnClasses(step) {
      const status = this.storeConfigStatus(step);
      return this.badge[status].classes;
    },
    btnText(step) {
      const status = this.storeConfigStatus(step);
      return this.badge[status].text;
    },
    storeConfigStatus(step) {
      return this.store[step.configStatus];
    },
    goToNextStep() {
      if (this.nextStepId) {
        this.selectedStepId = this.nextStepId;
      }
    },
  },
};
</script>
