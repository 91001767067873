<template>
  <div v-if="value != null" :class="`inline-block px-2 py-1 rounded-xl ${valueClass}`">{{ value }} %</div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    dashed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valueClass() {
      if (this.value == null) return;
      if (this.value > 80)
        return `bg-green-100 text-green-500 border border-green-300 ${this.dashed ? "border-dashed" : ""}`;
      if (this.value >= 50)
        return `bg-yellow-100 text-yellow-500 border border-yellow-300 ${this.dashed ? "border-dashed" : ""}`;
      return `bg-red-100 text-red-500 border border-red-300 ${this.dashed ? "border-dashed" : ""}`;
    },
  },
};
</script>
