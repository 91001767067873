<template>
  <div class="bg-white">
    <div class="relative text-button-font-primary bg-primary">
      <div class="absolute inset-0">
        <img
          class="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1523815378073-a43ae3fbf36a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80"
          alt=""
        />
        <div class="absolute inset-0 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 class="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
          {{ header.title }}
        </h1>
        <p class="mt-6 text-xl max-w-3xl">
          {{ header.tagline }}
        </p>
      </div>
    </div>
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
      <div class="max-w-3xl mx-auto text-center">
        <h2 class="text-3xl font-extrabold text-gray-900">
          {{ rundown.header }}
          <span class="text-3xl font-extrabold text-purple-900">
            {{ rundown.headerMore }}
          </span>
        </h2>
      </div>
      <div class="mt-12">
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          <div v-for="(component, id) in rundown.components" :key="id" class="pt-6">
            <div class="flow-root rounded-lg bg-gray-50 px-6 pb-8 h-full">
              <div>
                <div class="mt-3">
                  <img :src="component.icon" class="w-20 h-auto" alt="..." />
                </div>
                <h3 class="mt-3 text-lg font-medium tracking-tight text-gray-900">
                  {{ component.title }}
                </h3>
                <p class="mt-3 text-base text-gray-500">
                  {{ component.body }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-50">
      <div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            {{ faq.header }}
          </h2>
          <dl class="mt-6 space-y-6 divide-y divide-gray-200">
            <Disclosure as="div" v-for="(question, id) in faq.questions" :key="id" class="pt-6" v-slot="{ open }">
              <dt class="text-lg">
                <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                  <span class="font-medium text-gray-900">
                    {{ question.title }}
                  </span>
                  <span class="ml-6 h-7 flex items-center">
                    <ChevronDownIcon
                      :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']"
                      aria-hidden="true"
                    />
                  </span>
                </DisclosureButton>
              </dt>
              <DisclosurePanel as="dd" class="mt-2 pr-12">
                <p class="text-base text-gray-500">
                  {{ question.body }}
                </p>
              </DisclosurePanel>
            </Disclosure>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <footer class="bg-gray-50">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div class="mt-8 md:mt-0 md:order-1">
        <p class="text-gray-400" style="font-size: 8px">
          ESTALY, Société de courtage en assurance au capital de 1 500 € Immatriculée au RCS de PARIS sous le n° 909 874
          109, inscrit à l’ORIAS sous le numéro 22 002 508, dont le siège social est situé 24 rue Malar – 75007 Paris,
          garantie financière et responsabilité civile professionnelle conformes aux Articles L512-6 et L512-7 du Code
          des Assurances. MMA IARD Assurances Mutuelles RCS Le Mans N°775 652 126 société d’assurance mutuelle à
          cotisations fixes et MMA IARD, S.A. au capital social de 537.052.368 €, RCS Le Mans N°440 048 882 ayant leurs
          sièges sociaux au 14 boulevard Marie et Alexandre Oyon -72030 Le Mans cedex 9. Ces entreprises sont régies par
          le Code des assurances et soumises au contrôle de l’Autorité de Contrôle Prudentiel et de Résolution, 4 place
          de Budapest, CS92459,75436 PARIS Cedex 09. Opérations d’assurances exonérées de TVA.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/outline";
import PlanDetailsService from "../../api/services/planDetails";
export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
  },
  data() {
    return {
      contractSku: this.$route.params.contractSku,
      header: {},
      faq: {},
      rundown: {},
    };
  },
  mounted() {
    this.getPlanDetails();
  },
  methods: {
    async getPlanDetails() {
      try {
        const response = await PlanDetailsService.get(this.contractSku);
        this.header = response.data.header;
        this.rundown = response.data.rundown;
        this.faq = response.data.faq;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
