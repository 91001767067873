<template>
  <button
    class="ml-4 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-primary-dark bg-white transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-primary"
    @click="this.openModal = true"
  >
    Classement des vendeurs
  </button>

  <div class="mt-10 shadow overflow-hidden sm:rounded-lg text-center mt-2">
    <SellerPerformanceModal
      v-bind:open="this.openModal"
      v-bind:challenge="challenge"
      v-bind:sellers="sellers"
      @close="this.openModal = false"
    />
  </div>
</template>

<script>
import SellerPerformanceModal from "@/components/seller/SellerPerformanceModal.vue";
export default {
  components: {
    SellerPerformanceModal,
  },

  data() {
    return {
      openModal: false,
    };
  },

  props: {
    challenge: Object,
    sellers: Object,
  },
};
</script>
