<style>
@media (max-width: 1024px) {
  .responsive-table {
    min-width: 1000px !important;
  }
}
</style>

<template>
  <div class="overflow-auto" style="max-height: 75vh">
    <TableContainer>
      <StoreStatsTable
        @sortChanged="onSortChanged"
        :totalStats="this.totalStats"
        :stats="this.storeStats"
        :onRowClick="handleStoreClick"
      />
    </TableContainer>
  </div>
</template>
<script>
import StoreStatsTable from "@/components/analytics/commission/StoreStatsTable.vue";
import TableContainer from "@/components/analytics/retail/utils/TableContainer.vue";

export default {
  components: {
    TableContainer,
    StoreStatsTable,
  },

  props: ["storeStats", "totalStats"],

  methods: {
    handleStoreClick(storeId) {
      this.$emit("storeIdSelected", storeId);
    },

    onSortChanged(sortParams) {
      this.$emit("sortChanged", {
        column: sortParams.column,
        order: sortParams.order,
      });
    },
  },
};
</script>
