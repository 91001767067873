<template>
  <div class="flow-root">
    <div>
      <div class="inline-block min-w-full align-middle">
        <div class="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
