import { securedAxiosInstance } from "../axios";
import { isStoreIdValid } from "@/utils/validation";
import { toSnakeCase } from "@/utils/utils.js";

class StoreService {
  getProducts(
    storeId,
    productTypes,
    vendors,
    offerStatuses,
    approvalStatuses,
    tags,
    keywords,
    title,
    source,
    parents,
    variants,
    online,
    offline,
    page,
    categories
  ) {
    const data = {
      filtering: {
        product_types: productTypes,
        vendors: vendors,
        offer_statuses: offerStatuses,
        approval_statuses: approvalStatuses,
        tags: tags,
        categories: categories,
      },
      keywords: keywords,
      title: title,
      source: source,
      parents: parents,
      variants: variants,
      online: online,
      offline: offline,
      page: page,
    };
    if (isStoreIdValid(storeId)) {
      return securedAxiosInstance.post(`/store/${storeId}/products`, data);
    }
  }

  getCategories(storeId) {
    if (isStoreIdValid(storeId)) {
      return securedAxiosInstance.get(`/store/${storeId}/product_categories`);
    }
  }

  getProductById(storeId, productId) {
    if (isStoreIdValid(storeId)) {
      return securedAxiosInstance.get(`/store/${storeId}/products/${productId}`);
    }
  }

  productPlatformUpdate(enabled, products_id, allProductsSelected, storeId, approvedProductsIds) {
    const data = {
      enabled: enabled,
      products: allProductsSelected ? approvedProductsIds : products_id,
    };

    if (isStoreIdValid(storeId)) {
      return securedAxiosInstance.put(`/store/${storeId}/platform`, data);
    }
  }

  getClaims({ storeId, data }) {
    if (isStoreIdValid(storeId)) {
      return securedAxiosInstance.get(`/store/${storeId}/claims`, {
        params: {
          stores: data.stores,
          status: data.status,
          page: data.page,
          keywords: data.keywords,
        },
      });
    }
  }

  getSellers(storeId, keywords, page) {
    if (isStoreIdValid(storeId)) {
      return securedAxiosInstance.get(`/store/${storeId}/sellers?keywords=${keywords}&page=${page}`);
    }
  }

  createSeller(storeId, data) {
    if (isStoreIdValid(storeId)) {
      return securedAxiosInstance.post(`/store/${storeId}/sellers`, data);
    }
  }

  updateSeller(storeId, sellerId, data) {
    if (isStoreIdValid(storeId)) {
      return securedAxiosInstance.put(`/store/${storeId}/sellers/${sellerId}`, data);
    }
  }

  getPlanSales(data, storeId) {
    if (isStoreIdValid(storeId)) {
      return securedAxiosInstance.post(`/store/${storeId}/plan_sales/list`, data);
    }
  }

  createOrder(storeId, orderParams) {
    return securedAxiosInstance.post(`/api/v1/store/${storeId}/orders`, orderParams);
  }

  getChallengesAndSellersPerformance(storeId) {
    return securedAxiosInstance.get(`/store/${storeId}/challenges`);
  }

  getDiscount(storeId) {
    return securedAxiosInstance.get(`/store/${storeId}/discounts`);
  }

  getRetailSellersData(storeId, startDate, endDate, sortParams) {
    return securedAxiosInstance.get(
      `/store/${storeId}/analytics/retail/sellers` +
        `?sortColumn=${sortParams["column"]}&sortOrder=${sortParams["order"]}`,
      {
        params: {
          start: startDate,
          end: endDate,
        },
      }
    );
  }

  getRetailSalesData(storeId, startDate, endDate) {
    return securedAxiosInstance.get(`/store/${storeId}/analytics/retail/sales`, {
      params: {
        start: startDate,
        end: endDate,
      },
    });
  }

  getStores({ page = null, sales_channel = null, status = null } = {}) {
    const params = {};

    if (page) params.page = page;
    if (sales_channel) params.sales_channel = sales_channel;
    if (status) params.status = status;

    return securedAxiosInstance.get("/stores", { params });
  }

  getStore(storeId) {
    return securedAxiosInstance.get(`/stores/${storeId}`);
  }

  getStoreGeneralInformationsOptions(storeId) {
    return securedAxiosInstance.get(`/store/${storeId}/configuration/general_informations_options`);
  }

  updateStoreGeneralInformations(storeId, data) {
    return securedAxiosInstance.put(`/store/${storeId}/configuration/update_general_informations`, {
      store: toSnakeCase(data),
    });
  }

  getStoreContactInformations(storeId) {
    return securedAxiosInstance.get(`/store/${storeId}/configuration/contact_information`);
  }

  updateStoreContactInformation(storeId, data) {
    return securedAxiosInstance.put(`/store/${storeId}/configuration/update_contact_information`, data);
  }

  updateStoreStatus(storeId, status) {
    return securedAxiosInstance.put(`/stores/${storeId}/update_status`, { status });
  }

  getAfterSalesService(storeId) {
    return securedAxiosInstance.get(`/store/${storeId}/configuration/after_sales_service`);
  }

  updateAfterSalesService(storeId, data) {
    return securedAxiosInstance.put(`/store/${storeId}/configuration/update_after_sales_service`, {
      store: toSnakeCase(data),
    });
  }

  createStore() {
    return securedAxiosInstance.post("/stores");
  }

  updateStore(storeId, data) {
    return securedAxiosInstance.put(`/stores/${storeId}`, data);
  }
}

export default new StoreService();
