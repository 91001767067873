<template>
  <div v-if="this.categories !== undefined && this.categories !== null">
    <div class="bg-white p-4 rounded-md mt-4">
      <p class="text-xl font-heading-1 pb-4 tracking-tight text-gray-900">Documents contractuels</p>
      <div v-for="category in this.categories" :key="category.value">
        <a
          class="font-medium text-primary-dark hover:underline"
          v-if="category.value === productCategoryValue"
          :href="category.marketingPage"
          target="_blank"
        >
          <div class="flex">
            <div>
              <DocumentIcon class="h-4 w-4 mr-1" />
            </div>
            <p class="text-sm">{{ "Contrats " + $t(`${category.name}`) }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { DocumentIcon } from "@heroicons/vue/outline";

export default {
  components: {
    DocumentIcon,
  },

  props: ["categories", "productCategoryValue"],
};
</script>
