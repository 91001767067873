<template>
  <ClaimDetails v-bind:admin-view="true" />
</template>

<script>
import ClaimDetails from "@/components/claim/details/ClaimDetails.vue";

export default {
  components: {
    ClaimDetails,
  },
};
</script>
