<template>
  <Modal :open="open" :size="'big'" @close="closeModal">
    <div>
      <h1 class="flex items-center justify-center text-2xl font-bold">Veuillez télécharger le devis de ce sinistre</h1>
      <div class="rounded-md bg-yellow-50 p-4 mt-3 border-l-4 border-yellow-400">
        <div class="flex">
          <div class="shrink-0">
            <ExclamationIcon class="w-8 h-8 text-yellow-400" />
          </div>
          <div class="flex items-center ml-3 text-sm text-yellow-700">
            <p>Montant maximal de prise en charge : {{ claim.coverages?.compensationValue }} €</p>
          </div>
        </div>
      </div>
      <h3 class="mt-6 text-sm">
        Cette déclaration de sinistre a été approuvée par Estaly pour une réparation. Pour pouvoir traiter ce sinistre,
        vous devez détailler le montant des coûts de réparations et déposer un devis proforma qui sera traité par nos
        équipes.
      </h3>
    </div>
    <div class="mt-3 flex">
      <p class="text-sm">L’état du produit est-il conforme à la déclaration du client ?</p>
      <div class="ml-3 flex items-center justify-around">
        <div class="flex items-center">
          <input
            name="conform"
            type="radio"
            class="h-4 w-4 border-gray-300 text-primary-dark focus:ring-primary"
            :value="true"
            v-model="conform"
          />
          <label class="ml-3 block text-sm font-medium leading-6 text-gray-900">Oui</label>
        </div>
        <div class="ml-3 flex items-center">
          <input
            name="non-conform"
            type="radio"
            class="h-4 w-4 border-gray-300 text-primary-dark focus:ring-primary"
            :value="false"
            v-model="conform"
          />
          <label class="ml-3 block text-sm font-medium leading-6 text-gray-900">Non</label>
        </div>
      </div>
    </div>
    <div v-if="conform === true" class="flex mt-3">
      <p class="text-sm">Le produit est-il réparable ?</p>
      <div class="ml-3 flex items-center justify-around">
        <div class="flex items-center">
          <input
            name="product-fixeable"
            type="radio"
            class="h-4 w-4 border-gray-300 text-primary-dark focus:ring-primary"
            :value="true"
            v-model="isProductFixable"
          />
          <label class="ml-3 block text-sm font-medium leading-6 text-gray-900">Oui</label>
        </div>
      </div>
      <div class="ml-3 flex items-center">
        <input
          name="product-not-fixeable"
          type="radio"
          class="h-4 w-4 border-gray-300 text-primary-dark focus:ring-primary"
          :value="false"
          v-model="isProductFixable"
        />
        <label class="ml-3 block text-sm font-medium leading-6 text-gray-900">Non</label>
      </div>
    </div>
    <FormField
      v-if="conform === false"
      class="mt-3"
      v-model="nonConformityReason"
      type="text"
      label="Veuillez décrire en quoi l'état du produit n'est pas conforme à la déclaration"
      name="nonConformityReason"
      id="nonConformityReason"
      required
    />
    <FixeableProductProcess
      v-if="isProductFixable === true"
      @update="
        (newProductState, newFile, newcostItems) => {
          productState = newProductState;
          file = newFile;
          costItems = newcostItems;
        }
      "
    />
    <FormField
      v-if="isProductFixable === false"
      v-model="productNotFixableReason"
      class="mt-3"
      type="text"
      label="Veuillez décrire en quoi le produit n'est pas réparable"
      name="nonFixableReason"
      id="nonFixableReason"
      required
    />
    <PricingDetails :claim="claim" :costItems="costItems" />
    <div v-if="validForm" class="mt-7 flex justify-center text-sm">
      <GenericButton @onClick="send">Valider et envoyer</GenericButton>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import { mapActions } from "vuex";
import FixeableProductProcess from "@/components/claim/details/information/action/quotations/FixeableProductProcess.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import PricingDetails from "@/components/claim/details/information/action/cost_items/PricingDetails.vue";
import { isPresent } from "@/utils/validation";
import { ExclamationIcon } from "@heroicons/vue/solid";
import FormField from "@/components/utils/FormField.vue";
export default {
  components: {
    FormField,
    ExclamationIcon,
    GenericButton,
    FixeableProductProcess,
    Modal,
    PricingDetails,
  },
  props: ["claim", "open"],
  computed: {
    validForm() {
      if (this.conform === null) return false;

      if (this.conform) {
        const productFixableInformationsValid =
          this.isProductFixable && isPresent(this.file) && isPresent(this.costItems) && isPresent(this.productState);
        const productNonFixableInformationsValid = !this.isProductFixable && isPresent(this.productNotFixableReason);

        if (productFixableInformationsValid || productNonFixableInformationsValid) return true;
      } else {
        return this.nonConformityReason !== null;
      }

      return false;
    },
  },
  data: () => {
    return {
      file: null,
      costItems: null,
      isProductFixable: null,
      productState: null,
      productNotFixableReason: null,
      conform: null,
      nonConformityReason: null,
    };
  },
  watch: {
    conform(newValue) {
      if (newValue === true) {
        this.productNotFixableReason = null;
        this.nonConformityReason = null;
        this.file = null;
        this.costItems = null;
        this.isProductFixable = null;
        this.productState = null;
      } else if (newValue === false) {
        this.file = null;
        this.costItems = null;
        this.isProductFixable = null;
        this.productState = null;
      }
    },
    isProductFixable(newValue) {
      if (newValue === true) {
        this.productNotFixableReason = null;
      } else if (newValue === false) {
        this.file = null;
        this.costItems = null;
        this.productState = null;
      }
    },
  },
  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("claims", ["uploadClaimQuotation", "impossibleClaimQuotationUpload"]),

    async send() {
      try {
        if (!this.conform) {
          await this.impossibleClaimQuotationUpload({
            message: "Déclaration non conforme",
            reason: this.nonConformityReason,
          });
        } else if (this.isProductFixable) {
          await this.uploadClaimQuotation({
            costItems: this.costItems,
            quotationFile: this.file[0],
            productState: this.productState,
          });
        } else {
          await this.impossibleClaimQuotationUpload({
            message: "Produit non réparable",
            reason: this.productNotFixableReason,
          });
        }
        this.closeModal();
      } catch (error) {
        console.log(error);
        await this.notify({
          category: "simple",
          type: "error",
          title: "Error when trying to upload the claim quotation",
          text: error.response.data.error,
        });
      }
    },
    closeModal() {
      this.$emit("close");
      this.file = null;
      this.costItems = null;
      this.isProductFixable = null;
      this.productNotFixableReason = null;
      this.conform = null;
      this.nonConformityReason = null;
    },
  },
};
</script>
