import { isPresent } from "@/utils/validation";
import ClaimService from "@/api/services/claims";

const initialState = {
  currentClaim: null,
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    getClaimFriendlyId: (state) => {
      return isPresent(state.currentClaim) ? state.currentClaim.friendlyId : null;
    },
    doesClaimExists: (state) => {
      return isPresent(state.currentClaim);
    },
    answers: (state) => {
      return isPresent(state.currentClaim) ? JSON.parse(JSON.stringify(state.currentClaim.answers)) : null;
    },
  },

  mutations: {
    setCurrentClaim(state, currentClaim) {
      state.currentClaim = currentClaim;
    },
  },

  actions: {
    getClaim: async function ({ commit }, claimFriendlyId) {
      try {
        const response = await ClaimService.getClaimByFriendlyId(claimFriendlyId);
        commit("setCurrentClaim", response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    reset: function ({ commit }) {
      commit("setCurrentClaim", null);
    },

    impossibleClaimQuotationUpload: async function ({ state, dispatch }, { message, reason }) {
      await ClaimService.impossibleQuotationUpload(state.currentClaim, message, reason);
      dispatch("getClaim", state.currentClaim.friendlyId);
    },

    uploadClaimQuotation: async function ({ state, dispatch }, { costItems, quotationFile, productState }) {
      try {
        await ClaimService.addQuotationToClaim(state.currentClaim, costItems, quotationFile, productState);
        dispatch("getClaim", state.currentClaim.friendlyId);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    uploadClaimInvoice: async function ({ state, dispatch }, { costItems, invoiceFile }) {
      try {
        await ClaimService.addInvoiceToClaim(state.currentClaim, costItems, invoiceFile);
        dispatch("getClaim", state.currentClaim.friendlyId);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
