<template>
  <div>
    <form>
      <p class="block text-sm text-gray-600">
        Adresse
        <span class="ml-1 text-red-600">*</span>
      </p>
      <div class="mb-1">
        <input
          ref="autocompleteInput"
          id="autocomplete"
          type="text"
          placeholder="58 rue de la Victoire, Paris, France"
          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          @keydown.enter.prevent
        />
      </div>
      <div>
        <p v-if="addressError" class="mt-2 text-sm text-red-600">Une adresse valide est requise.</p>
      </div>
    </form>
  </div>
</template>

<script>
import { EventBus } from "@/utils/event_bus";
import { isPresent } from "@/utils/validation";

export default {
  props: {
    addressError: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialAddress: {
      type: String,
      required: false,
      default: null,
    },
  },

  data: () => {
    return {
      address: {
        street: "",
        city: "",
        province: "",
        country: "",
        zipCode: "",
      },
    };
  },
  mounted() {
    this.setupAutocomplete();
    this.setInitialAddress();
    EventBus.on("resetAddress", this.resetGoogleAddress);
  },

  methods: {
    setupAutocomplete() {
      if (window.google) {
        const google = window.google;
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete"));
        this.autocomplete.setComponentRestrictions({
          // restrict the country
          country: ["fr", "mq", "gp", "re"],
        });
        this.autocomplete.addListener("place_changed", () => {
          let place = this.autocomplete.getPlace();
          let ac = place.address_components;

          const streetNumber = this.findDataShortName(ac, "street_number");
          const route = this.findDataShortName(ac, "route");
          this.address.street =
            isPresent(streetNumber) && isPresent(route) ? `${streetNumber} ${route}` : place.formatted_address;
          this.address.city = this.findDataShortName(ac, "locality");
          this.address.province = this.findDataShortName(ac, "administrative_area_level_1");
          this.address.country = this.findDataShortName(ac, "country");
          this.address.zipCode = this.findDataShortName(ac, "postal_code");
          this.$emit("newAddress", this.address);
        });
      }
    },

    findDataShortName(ac, key) {
      return ac?.find((item) => item.types.includes(key))?.short_name;
    },

    resetGoogleAddress() {
      this.address = {
        street: "",
        city: "",
        province: "",
        country: "",
        zipCode: "",
      };
      if (this.$refs.autocompleteInput) {
        this.$refs.autocompleteInput.value = "";
      }
    },

    setInitialAddress() {
      if (this.initialAddress) {
        this.$refs.autocompleteInput.value = this.initialAddress;
      }
    },
  },
};
</script>
