<template>
  <div>
    <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">
      {{ $t("Declared Claims") }}
    </h1>
  </div>
</template>

<script>
export default {};
</script>
