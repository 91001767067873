<template>
  <CommonAnalyticsTable
    :stats="this.stats"
    :total="this.total"
    :currentMonth="currentMonth"
    :statsClickable="true"
    :showTargetColumns="true"
    @rowClicked="handleStoreClick"
    @sortChanged="sortChanged"
  />
</template>
<script>
import CommonAnalyticsTable from "@/components/analytics/retail/utils/CommonAnalyticsTable.vue";

export default {
  components: {
    CommonAnalyticsTable,
  },

  props: ["stats", "total", "currentMonth"],

  methods: {
    handleStoreClick(row) {
      this.$emit("storeIdSelected", row.id);
    },
    sortChanged(event) {
      this.$emit("sortChanged", event);
    },
  },
};
</script>
