import { startOfMonth, endOfMonth, subMonths, startOfWeek, endOfWeek, subWeeks } from "date-fns";

export function sameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export function currentMonth(startDate, endDate) {
  const now = new Date();
  return sameDay(startDate, startOfMonth(now)) && sameDay(endDate, endOfMonth(now));
}

export function previousMonth(startDate, endDate) {
  const now = new Date();
  const lastMonthStart = startOfMonth(subMonths(now, 1));
  const lastMonthEnd = endOfMonth(subMonths(now, 1));
  return sameDay(startDate, lastMonthStart) && sameDay(endDate, lastMonthEnd);
}

export function currentWeek(startDate, endDate) {
  const now = new Date();
  return (
    sameDay(startDate, startOfWeek(now, { weekStartsOn: 1 })) && sameDay(endDate, endOfWeek(now, { weekStartsOn: 1 }))
  );
}

export function previousWeek(startDate, endDate) {
  const now = new Date();
  const lastWeekStart = subWeeks(startOfWeek(now, { weekStartsOn: 1 }), 1);
  const lastWeekEnd = subWeeks(endOfWeek(now, { weekStartsOn: 1 }), 1);
  return sameDay(startDate, lastWeekStart) && sameDay(endDate, lastWeekEnd);
}

export function currentMonthRange() {
  const now = new Date();
  return [startOfMonth(now), endOfMonth(now)];
}
