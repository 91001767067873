<template>
  <div>
    <div>
      <FormField
        id="businessName"
        label="Raison sociale du magasin"
        labelClass="font-normal"
        name="businessName"
        required
        placeholder="Raison sociale"
        v-model="formData.businessName"
      />
      <FormField
        id="salesChannel"
        label="Nom commercial du magasin"
        labelClass="font-normal"
        name="salesChannel"
        required
        placeholder="Nom commercial"
        v-model="formData.salesChannel"
      />
      <FormField
        id="billingAddress"
        label="Adresse postale du magasin"
        labelClass="font-normal"
        name="billingAddress"
        required
        placeholder="Adresse postale"
        v-model="formData.billingAddress"
      />
      <Selector
        id="revenue_range"
        label="Revenue range"
        labelClass="font-normal"
        name="revenue_range"
        required
        v-model="formData.revenueRange"
        :options="revenueRanges"
      />
    </div>
    <div class="mt-4 flex justify-end">
      <GenericButton @click="updateGeneralInformations">
        <span v-if="store[configStatus] == 'pending_validation'">Modifier</span>
        <span v-else>Valider</span>
      </GenericButton>
    </div>
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import Selector from "@/components/utils/Selector.vue";
import FormField from "@/components/utils/FormField.vue";
import StoreService from "@/api/services/store";
import { mapActions } from "vuex";

export default {
  props: ["store", "configStatus"],

  components: {
    GenericButton,
    Selector,
    FormField,
  },

  data() {
    return {
      formData: {
        businessName: this.store.businessName || "",
        salesChannel: this.store.salesChannel || "",
        billingAddress: this.store.billingAddress || "",
        revenueRange: this.store.revenueRange || "",
      },
      revenueRanges: [],
    };
  },

  created() {
    this.fetchOptions();
  },

  emits: ["configurationUpdated"],

  methods: {
    ...mapActions("notifications", ["notify"]),
    async fetchOptions() {
      const storeId = this.store.id;
      try {
        const response = await StoreService.getStoreGeneralInformationsOptions(storeId);
        this.revenueRanges = response.data.revenue_ranges;
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur s'est produite lors du chargement du formulaire",
          text: error.response?.data?.error || error.message,
        });
      }
    },
    async updateGeneralInformations() {
      const storeId = this.store.id;
      try {
        const payload = {
          ...this.formData,
        };
        await StoreService.updateStoreGeneralInformations(storeId, payload);
        this.$emit("configurationUpdated");
        this.$emit("stepValidated");
        this.notify({
          category: "simple",
          type: "success",
          title: "Informations mises à jour",
          text: "Les informations générales ont été mises à jour avec succès",
        });
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur s'est produite",
          text: error.response?.data?.error || error.message,
        });
      }
    },
    deduplicate(options) {
      return [...new Map(options.map((option) => [option.value, option])).values()];
    },
  },
};
</script>
