import { securedAxiosInstance } from "../axios";

class AnalyticsService {
  getRetailSalesData(startDate, endDate, sortParams) {
    return securedAxiosInstance.get("/admin/analytics/retail/sales", {
      params: {
        start: startDate,
        end: endDate,
        sortColumn: sortParams["column"],
        sortOrder: sortParams["order"],
      },
    });
  }

  getRetailSellersData(storeId, startDate, endDate, sortParams) {
    return securedAxiosInstance.get("/admin/analytics/retail/sellers", {
      params: {
        start: startDate,
        end: endDate,
        store_id: storeId,
        sortColumn: sortParams["column"],
        sortOrder: sortParams["order"],
      },
    });
  }

  getCommissionSalesData(startDate, endDate, sortParams) {
    return securedAxiosInstance.get("/admin/analytics/commission/stores", {
      params: {
        start: startDate,
        end: endDate,
        sort_column: sortParams["column"],
        sort_order: sortParams["order"],
      },
    });
  }

  getCommissionSellersData(storeId, startDate, endDate, sortParams) {
    return securedAxiosInstance.get("/admin/analytics/commission/sellers", {
      params: {
        store_id: storeId,
        start: startDate,
        end: endDate,
        sort_column: sortParams["column"],
        sort_order: sortParams["order"],
      },
    });
  }

  getGeneralAnalytics({ startDate, endDate, stores }) {
    return securedAxiosInstance.get("/admin/analytics", {
      params: {
        start_date: startDate,
        end_date: endDate,
        stores,
      },
    });
  }
}

export default new AnalyticsService();
