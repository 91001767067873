<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <HeadlessUIDialog as="div" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full lg:ml-20 max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative"
            >
              <div class="absolute top-4 right-4">
                <XIcon class="w-6 h-6 cursor-pointer" @click="closeSaleModal" />
              </div>

              <InsuranceSaleTitle :product="product" />

              <div v-if="!contractsAvailable" class="flex">
                <div class="text-sm font-medium text-red-700 pt-2">
                  <p class="break-words text-center">
                    {{ $t("There are no contracts available for this price") }}
                  </p>
                </div>
              </div>

              <div v-else-if="saleCreationSuccess">
                <SuccessModal :customer="customer" />
              </div>

              <div v-else>
                <VeeForm v-slot="{ handleSubmit }" as="div">
                  <form class="space-y-4" @submit="handleSubmit($event, handleOrderCreation)" ref="saleCreationForm">
                    <div class="flex flex-col">
                      <div class="flex gap-4 mt-4 flex-col sm:flex-row">
                        <RadioGroup v-model="contractSelected">
                          <RadioGroupLabel class="text-2xl font-bold">Type de contrat</RadioGroupLabel>

                          <div class="flex gap-4 mt-4 flex-col sm:flex-row">
                            <RadioGroupOption
                              as="template"
                              v-for="contract in yearlyContractsSortedByTermLength"
                              :key="contract.sku"
                              :value="contract"
                              v-slot="{ checked, active }"
                            >
                              <div
                                :class="[
                                  checked ? 'border-transparent' : 'border-gray-300',
                                  active ? 'border-primary ring-2 ring-primary' : '',
                                  'relative flex border bg-white p-4 shadow-sm focus:outline-none flex-grow rounded-3xl cursor-pointer',
                                ]"
                              >
                                <span class="flex flex-1">
                                  <span class="flex flex-col">
                                    <RadioGroupLabel as="span" class="block text-md font-medium text-gray-900">
                                      {{ contractDisplayTermLength(contract) }}
                                    </RadioGroupLabel>
                                    <RadioGroupDescription
                                      as="span"
                                      class="mt-1 flex items-center text-md text-gray-500"
                                    >
                                      {{ formatPrice(contract.price) }}
                                    </RadioGroupDescription>
                                    <RadioGroupDescription
                                      as="span"
                                      class="mt-1 flex items-center text-xs text-gray-500"
                                    >
                                      {{ contract.sku }}
                                    </RadioGroupDescription>
                                  </span>
                                </span>
                                <CheckCircleIcon
                                  :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-primary-dark']"
                                  aria-hidden="true"
                                />
                                <span
                                  :class="[
                                    active ? 'border' : 'border-2',
                                    checked ? 'border-primary' : 'border-transparent',
                                    'pointer-events-none absolute -inset-px rounded-3xl cursor-pointer',
                                  ]"
                                  aria-hidden="true"
                                />
                              </div>
                            </RadioGroupOption>
                          </div>
                          <p class="mt-2 text-sm text-red-600" v-show="showContractRequiredErrorMessage">
                            {{ $t("Please select the insurance contract sold.") }}
                          </p>
                        </RadioGroup>
                      </div>
                    </div>

                    <div class="mt-6">
                      <div class="flex flex-row gap-4">
                        <div class="sm:w-1/2">
                          <button
                            class="w-full border border-transparent text-button-font-primary bg-primary px-4 py-2 text-lg font-medium transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2"
                            type="submit"
                          >
                            {{ $t("Record a sale") }}
                          </button>
                        </div>

                        <div class="sm:w-1/2">
                          <button
                            class="w-full border border-transparent bg-red-100 px-4 py-2 text-lg font-medium text-red-700 transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                            @click="closeSaleModal"
                          >
                            {{ $t("Close") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </VeeForm>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </HeadlessUIDialog>
  </TransitionRoot>
</template>

<script>
import { Form as VeeForm } from "vee-validate";
import {
  Dialog as HeadlessUIDialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
} from "@headlessui/vue";
import { CheckCircleIcon, XIcon } from "@heroicons/vue/outline";

import InsuranceSaleTitle from "@/components/payment/InsuranceSaleTitle.vue";
import SuccessModal from "@/views/pos/home/form/SuccessModal.vue";

import StoreService from "@/api/services/store";

import { mapActions, mapMutations } from "vuex";

import { formatPrice } from "@/utils/price_formatter";

export default {
  components: {
    VeeForm,
    HeadlessUIDialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    CheckCircleIcon,
    XIcon,
    InsuranceSaleTitle,
    SuccessModal,
  },

  props: {
    isOpen: Boolean,
    saleRecording: Object,
    customer: Object,
    address: Object,
    sellerId: String,
    product: Object,
  },

  data: () => {
    return {
      subscriptionLink: "",
      contractSelected: "",
      saleCreationSuccess: false,
      showContractRequiredErrorMessage: false,
    };
  },

  computed: {
    contractsAvailable() {
      return this.saleRecording.contractDetails.length > 0;
    },

    yearlyContractsSortedByTermLength() {
      const contracts = this.saleRecording.contractDetails;
      const filteredContracts = contracts.filter((contract) => !contract.isMonthly);

      return filteredContracts.sort((a, b) => a.termLength - b.termLength);
    },
  },

  methods: {
    ...mapMutations("store", ["setIsLoading"]),
    ...mapActions("notifications", ["notify"]),
    formatPrice,

    resetAttributes() {
      this.subscriptionLink = "";
      this.contractSelected = "";
      this.saleCreationSuccess = false;
      this.showContractRequiredErrorMessage = false;
    },

    contractDisplayTermLength(contract) {
      const termLengthInYears = contract.termLength / 12;
      return `${termLengthInYears} ${this.$tc("year", termLengthInYears)}`;
    },

    closeSaleModal() {
      this.$emit("closeSaleModal", this.saleCreationSuccess);
      if (this.saleCreationSuccess) {
        this.resetAttributes();
      }
    },

    async handleOrderCreation() {
      if (!this.contractSelected) {
        this.showContractRequiredErrorMessage = true;
        return;
      }

      try {
        this.setIsLoading(true);
        const customerParams = {
          email: this.customer.email,
          first_name: this.customer.firstName,
          last_name: this.customer.lastName,
          phone: this.customer.phoneNumber,
          birth_date: this.customer.birthDate,
        };
        const productParams = {
          title: this.product.productName,
          price: this.product.productPrice,
          reference_id: this.product.productReference,
          product_category: this.product.productCategory?.value,
        };
        const insuranceParams = {
          offer_id: this.contractSelected.sku,
          price: this.contractSelected.price,
          quantity: 1,
          product: productParams,
        };
        const addressParams = {
          address1: this.address.street,
          city: this.address.city,
          province: this.address.province,
          country: this.address.country,
          zip_code: this.address.zipCode,
        };
        const orderParams = {
          source: "offline",
          order: {
            customer: customerParams,
            insurance: insuranceParams,
            address: addressParams,
            seller_id: this.sellerId,
            charged_by: "merchant",
          },
        };
        await StoreService.createOrder(this.$store.state.store.selectedStoreId, orderParams);
        this.notify({
          category: "simple",
          type: "success",
          title: "The sale has been recorded",
          text: "Refresh your dashboard to see it displayed on Plan Sales",
        });
        this.saleCreationSuccess = true;
        this.showContractRequiredErrorMessage = false;
      } catch (error) {
        console.log(error);
        this.notify({
          category: "simple",
          type: "error",
        });
      } finally {
        this.setIsLoading(false);
      }
    },
  },
};
</script>
