<template>
  <div>
    <div class="min-h-5 mb-5">
      <a
        href="#"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        v-on:click="handleStoreClick(null)"
      >
        Précédent
      </a>
    </div>
    <CommonAnalyticsTable
      :stats="this.stats"
      :total="this.total"
      :currentMonth="currentMonth"
      @sortChanged="sortChanged"
    />
  </div>
</template>
<script>
import CommonAnalyticsTable from "@/components/analytics/retail/utils/CommonAnalyticsTable.vue";

export default {
  components: {
    CommonAnalyticsTable,
  },

  props: ["stats", "total", "currentMonth"],

  methods: {
    handleStoreClick(storeId) {
      this.$emit("storeIdSelected", storeId);
    },
    sortChanged(event) {
      this.$emit("sortChanged", event);
    },
  },
};
</script>
