<template>
  <div class="relative inline-block">
    <div class="informationIcon" @mouseenter="hovered = true" @mouseleave="hovered = false">
      <InformationCircleIcon class="h-5 w-4 text-primary-dark" aria-hidden="true" />
    </div>
    <div :class="['tooltip shadow-lg', hovered ? 'block' : 'hidden']">
      <span :class="['text', textSize]">{{ text }}</span>
    </div>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";

export default {
  components: {
    InformationCircleIcon,
  },

  props: {
    text: {
      type: String,
      required: true,
    },
    textSize: {
      type: String,
      required: false,
      default: "text-xs",
    },
  },

  data() {
    return {
      hovered: false,
    };
  },
};
</script>

<style scoped>
.tooltip {
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #8c1efc;

  width: 260px;
  bottom: 100%;
  left: 50%;
  margin-left: -130px;
  transition: opacity 1s;

  position: absolute;
  z-index: 3;

  background: white;
}

.text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #8c1efc transparent transparent transparent;
}
</style>
