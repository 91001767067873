<template>
  <div class="pt-2">
    <label :for="id" :class="`block text-sm leading-6 text-gray-900 sm:pt-1.5 ${labelClass}`">
      {{ label }}
      <span v-if="required" class="text-red-600">*</span>
    </label>
    <Listbox as="div" class="cursor-pointer" multiple v-model="internalValue">
      <div class="relative">
        <ListboxButton
          class="relative w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm min-h-9 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm sm:max-w-xs"
        >
          <span class="block truncate">
            {{ selectedOptions }}
          </span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>
        <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            :id="id"
            class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm sm:max-w-xs"
          >
            <ListboxOption
              v-for="option in options"
              :key="option.value"
              :value="option.value"
              v-slot="{ active, selected }"
            >
              <li
                :class="[
                  active ? 'text-white bg-primary' : 'text-gray-900',
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                ]"
              >
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                  {{ option.label }}
                </span>

                <span
                  v-if="selected"
                  :class="[
                    active ? 'text-white' : 'text-primary-dark',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/solid";

export default {
  components: {
    CheckIcon,
    ChevronDownIcon,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    labelClass: {
      type: String,
      default: "font-semibold",
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
  },

  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    selectedOptions: {
      get() {
        const text = this.modelValue
          .map((value) => {
            const option = this.options.find((option) => option.value === value);
            return option ? option.label : value;
          })
          .join(", ");
        return text.length > 0 ? text : this.placeholder;
      },
    },
  },

  emits: ["update:modelValue"],
};
</script>
