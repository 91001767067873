import { plainAxiosInstance, securedAxiosInstance } from "../axios";

class AuthService {
  signin(user) {
    return plainAxiosInstance.post("/user/sessions", {
      ...user,
    });
  }

  signup(params) {
    return plainAxiosInstance.post("/user/registrations", params);
  }

  resetPassword(id, data) {
    return plainAxiosInstance.patch(`/user/password_resets/${id}`, data);
  }

  signout() {
    return securedAxiosInstance.delete("/user/sessions");
  }
}

export default new AuthService();
